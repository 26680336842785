import React from 'react'
import { toAbsoluteUrl } from 'src/_metronic/helpers'

type Props = {}

const Header = (props: Props) => {
    return (
        <div className='d-flex py-1 py-4 align-items-center justify-content-center bg-white'>
            <img
                alt='Logo'
                className='w-60px w-md-100px'
                src={toAbsoluteUrl('/media/icons/padma/LogoPadma.png')}
            />
        </div>
    )
}

export default Header