import React, { useEffect } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { Link } from 'react-router-dom'
import AuthScreens from '../../AuthScreens'

const GuestWrapper: React.FC<{ withTitle?: boolean, isMobile?: boolean }> = ({ withTitle = true, isMobile = false, children }) => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid position-relative w-100 overflow-hidden h-100-svh'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-8 pb-lg-20'>
        {isMobile && <InlineSVG src={'/media/icons/padma/LogoPadma.svg'} className='mb-12' />}

        {withTitle && !isMobile && (
          <Link to={AuthScreens.LOGIN_EMAIL.PATH} className='mb-12 fs-1 text-dark fw-medium'>
            <InlineSVG src={'/media/icons/padma/LogoPadma.svg'} />
          </Link>
        )}
        <div className='w-100 w-md-400px w-lg-512px bg-white p-6 p-lg-12 mx-auto position-relative z-index-1'
          style={{ borderRadius: 10, boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.08)" }}
        >{children}</div>
      </div>
      <InlineSVG
        src={'/media/icons/padma/pattern-1.svg'}
        className="guest-pattern-1" />
      <InlineSVG
        src={'/media/icons/padma/pattern-1.svg'}
        className="guest-pattern-2" />
    </div>
  )
}

export default GuestWrapper
