import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const SettingsScreens = {
  SETTINGS: {
    PATH: '/settings',
    TITLE: 'Settings',
    TITLE_ALT: 'Settings',
    PERMISSION: 'settings.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRIVACY_POLICY: {
    PATH: '/settings/privacy-policy',
    TITLE: 'Privacy Policy',
    TITLE_ALT: 'Privacy Policy',
    PERMISSION: 'privacy_policy.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ABOUT_US: {
    PATH: '/settings/about-us',
    TITLE: 'About Us',
    TITLE_ALT: 'About Us',
    PERMISSION: 'about_us.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  TNC: {
    PATH: '/settings/tnc',
    TITLE: 'Terms and Conditions',
    TITLE_ALT: 'Terms and Conditions',
    PERMISSION: 'terms_and_condition.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  CONTACT_US: {
    PATH: '/settings/contact-us',
    TITLE: 'Contact Us',
    TITLE_ALT: 'Contact Us',
    PERMISSION: 'contact_us.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ: {
    PATH: '/settings/faq',
    TITLE: 'FAQ',
    TITLE_ALT: 'FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_FAQ: {
    PATH: '/settings/faq/:type/list',
    TITLE: '',
    TITLE_ALT: '',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_FAQ_CATEGORY: {
    PATH: '/settings/faq/category/list',
    TITLE: 'List FAQ Categories',
    TITLE_ALT: 'List FAQ Categories',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_FAQ_CATEGORY: {
    PATH: '/settings/faq/category/list/add',
    TITLE: 'Add FAQ Category',
    TITLE_ALT: 'Add FAQ Category',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ_CATEGORY: {
    PATH: '/settings/faq/category/list/edit/:id',
    TITLE: 'Edit FAQ Category',
    TITLE_ALT: 'Edit FAQ Category',
    PERMISSION: 'faq.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_FAQ_CATEGORY: {
    PATH: '/settings/faq/category/list/detail/:id',
    TITLE: 'Detail FAQ Category',
    TITLE_ALT: 'Detail FAQ Category',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_FAQ_CONTENT: {
    PATH: '/settings/faq/content/list',
    TITLE: 'List FAQ Contents',
    TITLE_ALT: 'List FAQ Contents',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_FAQ_CONTENT: {
    PATH: '/settings/faq/content/list/add',
    TITLE: 'Add FAQ Content',
    TITLE_ALT: 'Add FAQ Content',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ_CONTENT: {
    PATH: '/settings/faq/content/list/edit/:id',
    TITLE: 'Edit FAQ Content',
    TITLE_ALT: 'Edit FAQ Content',
    PERMISSION: 'faq.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_FAQ_CONTENT: {
    PATH: '/settings/faq/content/list/detail/:id',
    TITLE: 'Detail FAQ Content',
    TITLE_ALT: 'Detail FAQ Content',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  NOTIFICATIONS: {
    PATH: '/settings/notifications',
    TITLE: 'Notification',
    TITLE_ALT: 'Notification',
    PERMISSION: 'list_notification.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_NOTIFICATION: {
    PATH: '/settings/notifications/list',
    HEADER_NAME: 'Notification',
    TITLE: 'Notification',
    TITLE_ALT: 'Notification',
    PERMISSION: 'list_notification.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_NOTIFICATION: {
    PATH: '/settings/notifications/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Notification',
    TITLE_ALT: 'Add Notification',
    PERMISSION: 'list_notification.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_NOTIFICATION: {
    PATH: '/settings/notifications/list/detail/:id',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Notification',
    TITLE_ALT: 'Detail Notification',
    PERMISSION: 'list_notification.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  REUSE_NOTIFICATION: {
    PATH: '/settings/notifications/list/reuse/:id',
    HEADER_NAME: 'Add',
    TITLE: 'Add Notification',
    TITLE_ALT: 'Add Notification',
    PERMISSION: 'list_notification.create',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const privacyPolicyBreadcrumbs = generateBreadcrumb([SettingsScreens.PRIVACY_POLICY])
const aboutUsBreadcrumbs = generateBreadcrumb([SettingsScreens.ABOUT_US])
const contactUsBreadcrumbs = generateBreadcrumb([SettingsScreens.CONTACT_US])
const FAQBreadcrumbs = generateBreadcrumb([SettingsScreens.FAQ])
const NotificationsBreadcrumbs = generateBreadcrumb([SettingsScreens.NOTIFICATIONS])

SettingsScreens.PRIVACY_POLICY.breadcrumbs = () => privacyPolicyBreadcrumbs
SettingsScreens.ABOUT_US.breadcrumbs = () => aboutUsBreadcrumbs
SettingsScreens.CONTACT_US.breadcrumbs = () => contactUsBreadcrumbs
SettingsScreens.FAQ.breadcrumbs = () => FAQBreadcrumbs
SettingsScreens.NOTIFICATIONS.breadcrumbs = () => NotificationsBreadcrumbs

export default SettingsScreens
