import { useFormik } from 'formik'
import { ReactNode, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useCancelableFn } from 'src/app/hooks/cancelable-hook'
import { getTitle } from 'src/app/utils/title-utils'
import * as Yup from 'yup'
import { mobileAccountVerification, mobileResetPasswordVerification, mobileSaveResetPassword, mobileSaveResetPin } from '../../redux/AuthCRUD'
import { Info, IsError, Type } from './MobileAccount.types'


export const useMobileAccount = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [responseCode, setResponseCode] = useState<string>();
    const [responseMessage, setResponseMessage] = useState<string>('');
    const [isError, setIsError] = useState<IsError>(null)
    const [info, setInfo] = useState<Info>()
    const [showForm, setShowForm] = useState<boolean>(false)
    let query = new URLSearchParams(useLocation().search)
    const token = query.get('token') as string
    const type = query.get('type') as Type

    // FORGOT PASSWORD
    const forgotPasswordSchema = Yup.object().shape({
        new_password: Yup.string()
            .matches(/^(?=.*[A-Z])(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*[0-9]).{8,}$/, () => (
                <>
                    <div>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.MUST_HAVE" })}</div>
                    <ul>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.MIN" }, { count: 8 })}</li>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.LETTER_AND_NUMBER" })}</li>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.LOWERCASE_AND_UPPERCASE" })}</li>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.SPECIAL_CHARACTERS" })}</li>
                    </ul>
                </>
            ))
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Password" })),
        confirm_password: Yup.string()
            .min(8, intl.formatMessage({ id: "ERROR.MIN" }, { count: 8 }))
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Confirm password" }))
            .test('equal', intl.formatMessage({ id: "CREATE_NEW_PASSWORD.PASSWORD_DOES_NOT_MATCH" }), function (v) {
                const ref = Yup.ref('new_password')
                return v === this.resolve(ref)
            }),
    })

    const forgotPasswordInitialValues = {
        new_password: '',
        confirm_password: '',
    }

    const forgotPasswordSubmitFn = useCancelableFn(async (payload: any, isActive) => {
        try {
            const result = await mobileSaveResetPassword(payload)
            const response_code = result.data?.response_schema?.response_code;
            const response_message = result.data?.response_schema?.response_message?.en;

            if (isActive()) {
                setResponseCode(response_code)
                setResponseMessage(response_message)
                setLoading(false)
                forgotPasswordFormik.setSubmitting(false)
                setShowForm(false)
                setIsError(false)
                setInfo({
                    title: intl.formatMessage({ id: "MOBILE_FORGOT_PASSWORD.SUCCESS.TITLE" }),
                    desc: intl.formatMessage({ id: "MOBILE_FORGOT_PASSWORD.SUCCESS.DESCRIPTION" }),
                })
            }

        } catch (e: any) {
            if (isActive()) {
                let response_code = e?.response?.data?.response_schema?.response_code
                let response_message = e?.response?.data?.response_schema?.response_message.en
                setShowForm(false)
                setResponseCode(response_code)
                setResponseMessage(response_message)
                setLoading(false)
                forgotPasswordFormik.setSubmitting(false)
                setIsError(true)
                setInfo({
                    title: intl.formatMessage({ id: "MOBILE_FORGOT_PASSWORD.ERROR.TITLE" }),
                    desc: intl.formatMessage({ id: "MOBILE_FORGOT_PASSWORD.ERROR.DESCRIPTION" }),
                })
            }
        }
    })

    const forgotPasswordFormik = useFormik({
        initialValues: forgotPasswordInitialValues,
        validateOnBlur: false,
        validateOnChange: true,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            const payload = {
                password: values.new_password,
                token,
            }
            forgotPasswordSubmitFn(payload)
        },
    })

    const resetTokenVerification = async (token: string, type: Type) => {
        try {
            const result = await mobileResetPasswordVerification(token, type)
            const response_code = result.data?.response_schema?.response_code;

            setShowForm(true)
            setResponseCode(response_code)
            setIsError(false)
            setInfo({
                title: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_VERIFIED.TITLE" }),
                desc: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_VERIFIED.DESCRIPTION" })
            })

        } catch (e: any) {
            const response_code = e?.response?.data?.response_schema?.response_code
            setShowForm(false)
            setResponseCode(response_code)
            setIsError(true)
            setInfo({
                title: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_EXPIRED.TITLE" }),
                desc: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_EXPIRED.DESCRIPTION" })
            })
        }
    }

    // RESET PIN

    const forgotPinInitialValues = {
        pin: '',
        confirm_pin: '',
    }

    const forgotPinSchema = Yup.object().shape({
        pin: Yup.string()
            .test('len', 'Must be exactly 6 characters', val => val?.toString().length === 6)
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "PIN" })),
        confirm_pin: Yup.string()
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Confirm PIN" }))
            .test('equal', intl.formatMessage({ id: "MOBILE_FORGOT_PIN.FORGOT_PIN.ERROR.INVALID_PIN" }), function (v) {
                const ref = Yup.ref('pin')
                return v === this.resolve(ref)
            }),
    })

    const forgotPinSubmitFn = useCancelableFn(async (payload: any, isActive) => {
        try {
            const result = await mobileSaveResetPin(payload)
            const response_code = result.data?.response_schema?.response_code;
            const response_message = result.data?.response_schema?.response_message?.en;

            if (isActive()) {
                setResponseCode(response_code)
                setResponseMessage(response_message)
                setLoading(false)
                forgotPinFormik.setSubmitting(false)
                setShowForm(false)
                setIsError(false)
                setInfo({
                    title: intl.formatMessage({ id: "MOBILE_FORGOT_PIN.SUCCESS.TITLE" }),
                    desc: intl.formatMessage({ id: "MOBILE_FORGOT_PIN.SUCCESS.DESCRIPTION" }),
                })
            }

        } catch (e: any) {
            if (isActive()) {
                let response_code = e?.response?.data?.response_schema?.response_code
                let response_message = e?.response?.data?.response_schema?.response_message.en
                setShowForm(false)
                setResponseCode(response_code)
                setResponseMessage(response_message)
                setLoading(false)
                forgotPinFormik.setSubmitting(false)
                setIsError(true)
                setInfo({
                    title: intl.formatMessage({ id: "MOBILE_FORGOT_PIN.ERROR.TITLE" }),
                    desc: intl.formatMessage({ id: "MOBILE_FORGOT_PIN.ERROR.DESCRIPTION" }),
                })
            }
        }
    })

    const forgotPinFormik = useFormik({
        initialValues: forgotPinInitialValues,
        validateOnBlur: false,
        validateOnChange: true,
        validationSchema: forgotPinSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            const payload = {
                pin: values.pin,
                token,
            }
            forgotPinSubmitFn(payload)
        },
    })

   


    // ACCOUNT VERIFICATION
    const accountVerification = async (token: string) => {
        try {
            const result = await mobileAccountVerification(token)
            const response_code = result.data?.response_schema?.response_code;

            setResponseCode(response_code)
            setIsError(false)
            setInfo({
                title: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_VERIFIED.TITLE" }),
                desc: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_VERIFIED.DESCRIPTION" })
            })

        } catch (e: any) {
            const response_code = e?.response?.data?.response_schema?.response_code

            setResponseCode(response_code)
            setIsError(true)
            setInfo({
                title: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_EXPIRED.TITLE" }),
                desc: intl.formatMessage({ id: "MOBILE_ACCOUNT.IS_EXPIRED.DESCRIPTION" })
            })
        }
    }

    useEffect(() => {

        if (token && type === "account-verification") {
            accountVerification(token)
        }

        if (token && ["forgot-password", "forgot-pin"].includes(type)) {
            resetTokenVerification(token, type)
        }

    }, [token, type])

    useEffect(() => {
        const title = {
            "forgot-password": intl.formatMessage({ id: "CREATE_NEW_PASSWORD.CREATE_NEW_PASSWORD" }),
            "account-verification": intl.formatMessage({ id: "MOBILE_ACCOUNT.ACCOUNT_VERIFICATION" }),
            "forgot-pin": intl.formatMessage({ id: "MOBILE_FORGOT_PIN.FORGOT_PIN" }),
        }

        document.title = getTitle(title[type])
    }, [type])



    return ({
        loading,
        responseCode,
        responseMessage,
        token,
        intl,
        isError,
        info,
        showForm,
        forgotPasswordFormik,
        forgotPinFormik
    })
}

export const isVisible = (types: Type[], type: Type, children: ReactNode, fallback?: ReactNode) => {
    if (types.includes(type)) return children
    return fallback
}