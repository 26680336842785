import { useCallback, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { RootState } from "src/setup"
import { logoutUser } from "../redux/AuthCRUD"

export const useLogout = () => {
    const history = useHistory()
    const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
    const [loggingOut, setLoggingOut] = useState<boolean>(false)
    const doLogout = useCallback(async () => {
        try {
            setLoggingOut(true)
            const result = await logoutUser()
            const response_code = result.data.response_schema.response_code;
            if (response_code === "PADMA-LOGOUT-200") {
                history.push('/logout')
            }

        } catch (error) {
            console.log('error :>> ', error);
        }
    }, [user])

    return {
        loggingOut,
        doLogout
    }
}