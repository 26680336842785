import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import DashboardScreens from '../modules/dashboard/DashboardScreens'
import DetailScreens from '../modules/detail/DetailScreens'
import LoyaltyInfoScreens from '../modules/loyalty-info/LoyaltyInfoScreens'
import PadmaMemberScreens from '../modules/padma-member/PadmaMemberScreens'
import PromotionBannerScreens from '../modules/promotion-banner/PromotionBannerScreens'
import PropertyScreens from '../modules/property/PropertySceens'
import SettingsScreens from '../modules/settings/SettingsScreens'
import UserManagementScreens from '../modules/user-managment/UserManagementScreens'
import OrderSettingsScreens from '../modules/order-settings/OrderSettingsScreens'
import ReportsScreens from '../modules/reports/ReportsScreens'
import LoyaltyLogsSyncScreens from '../modules/loyalty-logs-sync/LoyaltyLogsSyncScreens'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const DetailPage = lazy(() => import('../modules/detail/DetailRoutes'))
  const UserManagementPage = lazy(() => import('../modules/user-managment/UserManagementRoutes'))
  const PadmaMemberPage = lazy(() => import('../modules/padma-member/PadmaMemberRoutes'))
  const SettingsPage = lazy(() => import('../modules/settings/SettingsRoutes'))
  const LoyaltyInfoPage = lazy(() => import('../modules/loyalty-info/LoyaltyInfoRoutes'))
  const PromotionBannerPage = lazy(() => import('../modules/promotion-banner/PromotionBannerRoutes'))
  const PropertyPage = lazy(() => import('../modules/property/PropertyRoutes'))
  const OrderSettingsPage = lazy(() => import('../modules/order-settings/OrderSettingsRoutes'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsRoutes'))
  const LoyaltyLogsSyncPage = lazy(() => import('../modules/loyalty-logs-sync/LoyaltyLogsSyncRoutes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={DetailScreens.PROFILE.PATH} component={DetailPage} />
          <Route path={UserManagementScreens.USER_ROLE.PATH} component={UserManagementPage} />
          <Route path={PadmaMemberScreens.LIST_MEMBER.PATH} component={PadmaMemberPage} />
          <Route path={SettingsScreens.SETTINGS.PATH} component={SettingsPage} />
          <Route path={LoyaltyInfoScreens.LIST_LOYALTY_INFO.PATH} component={LoyaltyInfoPage} />
          <Route path={LoyaltyLogsSyncScreens.LIST_LOYALTY_LOGS_SYNC.PATH} component={LoyaltyLogsSyncPage} />
          <Route path={PromotionBannerScreens.LIST_PROMOTION_BANNER.PATH} component={PromotionBannerPage} />
          <Route path={PropertyScreens.LIST_PROPERTY.PATH} component={PropertyPage} />
          <Route path={OrderSettingsScreens.ORDER_SETTINGS.PATH} component={OrderSettingsPage} />
          <Route path={ReportsScreens.REPORT.PATH} component={ReportsPage} />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
