import { FileRejection } from 'react-dropzone'
import { PageToasts } from '../components/ToastComponent'

import _startCase from "lodash/startCase"
import _toLower from "lodash/toLower"

export const isNumeric = (text: string) => Boolean(text.match(/^[0-9]+$/))
export const trimNonNumeric = (text: string) => text.replace(/[^0-9]/g, '')
export const trimPhoneNumber = (text: string, prefix: string) => {
  let result = text
  if (text.startsWith('+') && text.length >= prefix.length) result = text.slice(prefix.length + 1)
  if (result.startsWith('62')) {
    result = result.slice(2)
  }
  if (result[0] === '0') {
    result = result.slice(1)
  }
  return trimNonNumeric(result)
}

export const needAddPrefix = (text: string) => !text.startsWith('62') || text.startsWith('0')
export const addPhonePrefix = (text: string) => {
  let result = text
  if (result[0] === '0') {
    result = result.slice(1)
  }
  return `62${trimNonNumeric(result)}`
}

export const trimPhoneBasic = (text: string) => {
  let result = text
  if (result.startsWith('62')) {
    result = result.slice(2)
  }
  return trimNonNumeric(result)
}

export const formatMoney = (number?: number, currency = '') =>
  `${number && number < 0 ? '-' : ''}${currency}${Math.abs(number ?? 0).toLocaleString('id-ID')}`

export const formatRupiah = (number?: number, invalid?: boolean, currency = 'Rp') => {
  if (invalid) return `${currency}-`
  return formatMoney(number, currency)
}

export const formatRupiahSpaced = (number?: number, invalid?: boolean, currency = 'Rp ') =>
  formatRupiah(number, invalid, currency)

//maxSize: in MB, dimension: in pixel
export const validateImage = ({
  image,
  rejects,
  field,
  preview_field,
  setFieldTouched,
  setFieldError,
  setFieldValue,
  maxSize,
  dimension,
}: {
  image?: File
  rejects?: FileRejection[]
  field: string
  preview_field?: string
  setFieldTouched?: Function
  setFieldError: Function
  setFieldValue: Function
  maxSize?: number
  dimension?: {
    width: number
    height: number
  }
}) => {
  if (!image) return

  const imageExist = validateImageExist(setFieldError, image, setFieldTouched, field, rejects)
  if (!imageExist) return

  const imageSizeValid = validateImageSize(setFieldError, image, maxSize, setFieldTouched, field)
  if (!imageSizeValid) return

  const _URL = window.URL || window.webkitURL
  const img = new Image()
  var objectUrl = _URL.createObjectURL(image)
  img.onload = async function () {
    if (
      dimension &&
      (Number((this as HTMLObjectElement).width) !== dimension.width ||
        Number((this as HTMLObjectElement).height) !== dimension.height)
    ) {
      setFieldError(field, `Image size not ${dimension.width}x${dimension.height}`)
    } else {
      setFieldError(field, undefined)
      if (setFieldTouched) setFieldTouched(field, true)
      await setFieldValue(preview_field, objectUrl)
      await setFieldValue(field, image, true)
    }
    img.remove()
  }
  img.src = objectUrl
}

const validateImageExist = (
  setFieldError: Function,
  image?: File,
  setFieldTouched?: Function,
  field?: string,
  rejects?: FileRejection[]
) => {
  if (!image) {
    if (setFieldTouched) setFieldTouched(field, true)
    if (rejects && rejects.length > 0) setFieldError(field, `File type not supported.`)
    return false
  }

  return true
}

const validateImageSize = (
  setFieldError: Function,
  image?: File,
  maxSize?: number,
  setFieldTouched?: Function,
  field?: string
) => {
  if (image && maxSize && image.size / 1024 / 1024 > maxSize) {
    if (setFieldTouched) setFieldTouched(field, true)
    setFieldError(field, `Max upload size is ${maxSize}MB.`)
    return false
  }
  return true
}

export const validateFile = async ({
  file,
  rejects,
  field,
  fileName,
  base64Field,
  previewField,
  setFieldError,
  setFieldValue,
  maxSize,
}: {
  file?: File
  rejects?: FileRejection[]
  field: string
  fileName?: string
  base64Field?: string
  previewField?: string
  setFieldError: Function
  setFieldValue: Function
  maxSize?: number
}) => {
  if (!file) {
    if (rejects && rejects.length > 0) await setFieldError(field, `File type not supported`)
    return
  }
  if (maxSize && file.size / 1024 / 1024 > maxSize) {
    await setFieldError(field, `Max upload size is ${maxSize}MB`)
    return
  }
  const _URL = window.URL || window.webkitURL
  const objectUrl = _URL.createObjectURL(file)
  const base64str = await getFormatedBase64FromUrl(objectUrl)
  await setFieldValue(field, file)
  await setFieldValue(fileName, file.name)
  await setFieldValue(base64Field, base64str)
  await setFieldValue(previewField, objectUrl)

  return objectUrl
}

export const appendFormArray = (
  formData: FormData,
  name: string,
  props: (string | undefined)[]
) => {
  props.forEach((value) => {
    if (value) formData.append(`${name}[]`, value)
  })
}

export const appendAllFormData = (
  formData: FormData,
  names: { key: string; name: string }[],
  inputs: Record<any, any>
) => {
  names.forEach((name) => {
    const data = inputs[name.key]
    if (data || typeof data === 'boolean') {
      if (Array.isArray(data)) appendFormArray(formData, name.name, data)
      else formData.append(name.name, data)
    }
  })
}

export const deleteFilesFormData = (
  formData: FormData,
  names: { key?: string; name: string }[],
  inputs: Record<any, any>,
  delete_field: string = 'delete_files'
) => {
  names.forEach((name) => {
    if (!inputs[name.name] && (!name.key || !!inputs[name.key])) {
      formData.append(`${delete_field}[]`, name.name)
    }
  })
}

export const appendAllRecordData = (
  formData: Record<string, string>,
  names: { key: string; name: string }[],
  inputs: Record<any, any>
) => {
  names.forEach((name) => {
    const data = inputs[name.key]
    if (data || typeof data === 'boolean') {
      formData[name.name] = data
    }
  })
}

export const copyText = (text: string, listener: (toast: PageToasts) => void) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      listener({ scheme: 'info', text: 'Text copied.', fixed: true })
    })
    .catch(() => {
      listener({ scheme: 'danger', text: 'Failed to copy text.', fixed: true })
    })
}

export const openWhatsapp = (phone: string) => {
  window.open(
    `https://api.whatsapp.com/send?phone=62${encodeURI(trimPhoneNumber(phone, '62'))}`,
    '_blank'
  )
}

export const getBase64FromUrl = async (url?: string) => {
  if (!url) return ''
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const createBase64 = (file: File | Blob) => {
  const _URL = window.URL || window.webkitURL
  return _URL.createObjectURL(file)
}

export const convertBlobToBase64 = (blob?: Blob | string) => {
  if (!blob) return
  if (typeof blob === 'string') return
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const getFormatedBase64FromUrl = async (url?: string) => {
  if (!url) return ''
  const base64Img = await getBase64FromUrl(url)
  return String(base64Img).split(',').pop()
}

export const validateBlob = async ({
  result,
  error,
  field,
  preview_field,
  setFieldTouched,
  setFieldError,
  setFieldValue,
  png = true,
}: {
  result?: Blob | null
  error?: any
  field: string
  preview_field?: string
  setFieldTouched?: Function
  setFieldError: Function
  setFieldValue: Function
  png?: boolean
}) => {
  if (error || !result) {
    setFieldError(field, error)
    if (setFieldTouched) setFieldTouched(field, true)
    return
  }

  const base64str = await convertBlobToBase64(result)
  const strImage = String(base64str).split(',').pop()
  await setFieldValue(field, strImage)
  await setFieldValue(preview_field, createBase64(result), true)
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getExtensionFromMime = (mime: string) => {
  switch (mime) {
    case 'image/png':
      return 'png'
    case 'image/gif':
      return 'gif'
    default:
      return 'jpg'
  }
}

export const blockInvalidChar = (e: any) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

// handle undefined and empty text for display
export const displayText = (text?: string | null): string => {
  if (text === null) return '-'
  if (typeof text === 'undefined') return '-'
  if (typeof text === 'string' && text.length === 0) return '-'
  return text
}

export const formatMoneyMultiCurrency = (number?: number, currency = 'IDR') => {
  if (currency !== 'IDR') {
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })
    return currency + ' ' + formatter.format(Number(number))
  }

  const formatter = new Intl.NumberFormat('id-ID')
  return currency + ' ' + formatter.format(Number(number))
}


export const titleCase = (str?: string) => _startCase(_toLower(str))

export const formatPhoneNumber = (dialCode?: string, phone?: string) => {
  if (!phone) return "-"
  if (!dialCode) return phone
  if (phone.includes(`+${dialCode}`)) return phone
  if (phone.startsWith('0')) return `+${dialCode}${phone.slice(1)}`
  return `+${dialCode}${phone}`
}