import { BaseResponse, Response } from 'src/app/models/api.types';
import axios, { AxiosRequestConfig } from 'axios'
import { Type } from '../partials/mobile-account/MobileAccount.types';

export const LOGIN_BY_EMAIL = `/v1/cms/login`
export const PROFILE = `/v1/cms/profile`
export const CHANGE_PASSWORD = `/v1/cms/change-password`
export const RESET_PASSWORD = `/v1/cms/change-password`
export const FORGOT_PASSWORD = `/v1/cms/change-password-notification`
export const ACCOUNT_VERIFICATION = `/v1/cms/change-password-notification`
export const VERIFY_CHANGE_PASSWORD = `/v1/cms/verify-change-password-token`
export const LOGOUT = `/v1/cms/logout`

// MOBILE
export const MOBILE_ACCOUNT_VERIFICATION = `/v1/app/membership/email/verify`
export const MOBILE_RESET_PASSWORD_VERIFICATION = `/v1/app/membership/verify-email-token`
export const MOBILE_RESET_PASSWORD = `/v1/app/membership/reset-password`
export const MOBILE_RESET_PIN = `/v1/app/membership/reset-pin`

export const loginByEmail = (
  email: string,
  password: string,
  token?: string,
  force?: boolean,
  config?: AxiosRequestConfig
) => {
  return axios.post(
    LOGIN_BY_EMAIL,
    { email, password, token, force },
    { baseURL: process.env.REACT_APP_API_URL_AUTH }
  )
}

export const changePassword = (data?: any, config?: any) => {
  return axios.post(CHANGE_PASSWORD, data, config)
}

export const forgotPassword = (data?: any, cancelToken?: any) => {
  return axios.post(`${FORGOT_PASSWORD}/forgot-password/email`, data, {
    cancelToken,
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}

export const requestAccountVerification = (data?: any, cancelToken?: any) => {
  return axios.post(`${ACCOUNT_VERIFICATION}/account-verification/email`, data, {
    cancelToken,
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}


export const verifyResetPassword = (token?: string, cancelToken?: any) => {
  return axios.get<BaseResponse<Response<any>>>(`${VERIFY_CHANGE_PASSWORD}/${token}`, {
    cancelToken,
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}


export const saveResetPassword = (token?: string, data?: any) => {
  return axios.post(`${RESET_PASSWORD}?token=${token}`, data, {
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}

export const logoutUser = (data?: any, bearerToken?: string) => {
  return axios.post(LOGOUT, data, {
    headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : undefined,
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}


export const mobileAccountVerification = (token?: string,cancelToken?: any) => {
  const URL = `${MOBILE_ACCOUNT_VERIFICATION}/${token}`
  return axios.post(URL, undefined, {
    cancelToken,
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}


export const mobileResetPasswordVerification = (token?: string, type?: Type, cancelToken?: any) => {
  const URL = `${MOBILE_RESET_PASSWORD_VERIFICATION}/${token}/${type}`
  return axios.post(URL, undefined, {
    cancelToken,
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}

export const mobileSaveResetPassword = (data?: any) => {
  return axios.put(MOBILE_RESET_PASSWORD, data, {
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}

export const mobileSaveResetPin = (data?: any) => {
  return axios.put(MOBILE_RESET_PIN, data, {
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  })
}