import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const LoyaltyLogsSyncScreens = {
    LIST_LOYALTY_LOGS_SYNC: {
        PATH: '/loyalty-logs-sync/list',
        HEADER_NAME: 'Loyalty Logs Sync',
        TITLE: 'List Loyalty Logs Sync',
        TITLE_ALT: 'List Loyalty Logs Sync',
        PERMISSION: 'loyalty_logs_sync.read',
        breadcrumbs: defaultBreadcrumbs,
    },
    DETAIL_LOYALTY_LOGS_SYNC: {
        PATH: '/loyalty-logs-sync/list/detail/:id',
        TITLE: 'Detail',
        TITLE_ALT: 'Detail',
        PERMISSION: 'loyalty_logs_sync.read',
        breadcrumbs: defaultBreadcrumbs,
    },
}

const loyaltyLogsSyncBreadcrumbs = generateBreadcrumb([LoyaltyLogsSyncScreens.LIST_LOYALTY_LOGS_SYNC])
LoyaltyLogsSyncScreens.DETAIL_LOYALTY_LOGS_SYNC.breadcrumbs = () => loyaltyLogsSyncBreadcrumbs

export default LoyaltyLogsSyncScreens
