import React, { FC, MouseEventHandler } from 'react'
import CustomButton from '../CustomButton';

export interface CustomModalHeaderProps {
    title: string;
    onClick: MouseEventHandler
}

const CustomModalHeader: FC<CustomModalHeaderProps> = ({
    title,
    onClick
}) => {
    return (
        <div className='modal-header d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center fs-4 text-neutral-500 fw-semibold fs-custom-18px'>{title}</div>
            <CustomButton variant='close' className='ms-2' onClick={onClick} />
        </div>
    )
}

export default CustomModalHeader