import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const OrderSettingsScreens = {
    ORDER_SETTINGS: {
        PATH: '/order-settings',
        TITLE: 'Order Settings',
        TITLE_ALT: 'Order Settings',
        PERMISSION: 'order_settings.read',
        breadcrumbs: defaultBreadcrumbs,
    },
}

const orderSettingsBreadcrumbs = generateBreadcrumb([OrderSettingsScreens.ORDER_SETTINGS])
OrderSettingsScreens.ORDER_SETTINGS.breadcrumbs = () => orderSettingsBreadcrumbs

export default OrderSettingsScreens
