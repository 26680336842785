import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const UserManagementScreens = {
  USER_ROLE: {
    PATH: '/user-management',
    TITLE: 'User Management',
    TITLE_ALT: 'User Management',
    PERMISSION: 'user_management.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROLE: {
    PATH: '/user-management/role/list/add',
    TITLE: 'Add Role',
    TITLE_ALT: 'Add Role',
    PERMISSION: 'list_user_role.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ROLE: {
    PATH: '/user-management/role/list/edit/:id',
    TITLE: 'Edit Role',
    TITLE_ALT: 'Edit Role',
    PERMISSION: 'list_user_role.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROLE: {
    PATH: '/user-management/role/list/detail/:id',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
    PERMISSION: 'list_user_role.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_ROLE: {
    PATH: '/user-management/role/list',
    HEADER_NAME: 'User & Role',
    TITLE: 'List User Role',
    TITLE_ALT: 'List User Role',
    PERMISSION: 'list_user_role.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_USER: {
    PATH: '/user-management/user/list',
    HEADER_NAME: 'User & Role',
    TITLE: 'List User Admin',
    TITLE_ALT: 'List User Admin',
    PERMISSION: 'list_user_admin.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_USER: {
    PATH: '/user-management/user/list/add',
    TITLE: 'Add User',
    TITLE_ALT: 'Add User',
    PERMISSION: 'list_user_admin.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_USER: {
    PATH: '/user-management/user/list/edit/:id',
    TITLE: 'Edit User',
    TITLE_ALT: 'Edit User',
    PERMISSION: 'list_user_admin.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_USER: {
    PATH: '/user-management/user/list/detail/:id',
    TITLE: 'Detail User',
    TITLE_ALT: 'Detail User',
    PERMISSION: 'list_user_admin.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const userBreadcrumbs = generateBreadcrumb([UserManagementScreens.LIST_USER])
const roleBreadcrumbs = generateBreadcrumb([UserManagementScreens.LIST_ROLE])

UserManagementScreens.ADD_USER.breadcrumbs = () => userBreadcrumbs
UserManagementScreens.EDIT_USER.breadcrumbs = () => userBreadcrumbs
UserManagementScreens.DETAIL_USER.breadcrumbs = () => userBreadcrumbs
UserManagementScreens.ADD_ROLE.breadcrumbs = () => roleBreadcrumbs
UserManagementScreens.EDIT_ROLE.breadcrumbs = () => roleBreadcrumbs
UserManagementScreens.DETAIL_ROLE.breadcrumbs = () => roleBreadcrumbs

export default UserManagementScreens
