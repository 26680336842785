/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useEffect } from 'react'
import ReactPinInput from 'react-pin-input'

type PinInputProps = {
  containerClassName?: string
  value?: string
  onChange?: (value: string) => void
  onComplete?: (value: string, index: number) => void
  isError?: boolean
  errorMessage?: string
  clear?: boolean
}

const PinInput = ({
  containerClassName,
  value,
  onChange,
  isError = false,
  errorMessage,
  clear,
  onComplete
}: PinInputProps) => {
  let pinInputRef: ReactPinInput | null

  useEffect(() => {
    if (pinInputRef) pinInputRef?.clear()
  }, [clear])

  return (
    <div
      className={clsx(
        'w-full flex items-center justify-center flex-col text-neutral-500',
        containerClassName
      )}
    >
      <ReactPinInput
        ref={(n) => (pinInputRef = n)}
        length={6}
        focus
        type='numeric'
        inputMode="number"
        onChange={onChange}
        autoSelect
        inputStyle={{ borderColor: '#9ca3af' }}
        inputFocusStyle={{ borderColor: '#D4A254' }}
        initialValue={value}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        onComplete={onComplete}
      />
      {errorMessage && isError && (
        <div className='fv-plugins-message-container text-danger mt-2'>
          <span role='alert'>{errorMessage}</span>
        </div>
      )}
    </div>
  )
}

export default PinInput
