import React, { FC, MouseEventHandler } from 'react'
import InlineSVG from 'react-inlinesvg/esm'

interface InfoCardAlertProps {
    variant: "success" | "warning"
    title: string;
    isShow: boolean;
    showVerificationLink?: boolean;
    onClickVerificationLink?: MouseEventHandler
}

const InfoCardAlert: FC<InfoCardAlertProps> = ({
    variant,
    title,
    isShow,
    showVerificationLink,
    onClickVerificationLink
}) => {

    const icons = {
        "success": "IconCheckGreen.svg",
        "warning": "IconWarning.svg"
    }

    if (!isShow) return null

    return (
        <div
            className='d-flex align-items-center rounded py-4 px-5 mb-10 bg-red-400'
            style={{ background: "#FFD2DA" }}
        >
            <InlineSVG src={`/media/icons/padma/${icons[variant]}`} className="w-24px me-4" />
            <span className='text-danger fs-6' style={{ flex: 1 }}>
                {title}
                {showVerificationLink && (
                    <>by clicking <button type='button' onClick={onClickVerificationLink} className='text-decoration-underline text-danger fs-6 fw-semibold border-0 bg-transparent p-0'>here</button></>
                )}
            </span>

        </div>
    )
}

export default InfoCardAlert