import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const PadmaMemberScreens = {
  LIST_MEMBER: {
    PATH: '/padma-member/list',
    HEADER_NAME: 'Padma Member',
    TITLE: 'Padma Member',
    TITLE_ALT: 'Padma Member',
    PERMISSION: 'padma_member.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_MEMBER: {
    PATH: '/padma-member/list/detail',
    HEADER_TITLE: "Detail",
    TITLE: 'Member Detail',
    TITLE_ALT: 'Member Detail',
    PERMISSION: 'padma_member.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_MEMBER_CUSTOMER_DATA: {
    PATH: '/padma-member/list/detail/:id/customer-data',
    HEADER_TITLE: "Detail",
    TITLE: 'Member Detail',
    TITLE_ALT: 'Member Detail',
    PERMISSION: 'padma_member.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_MEMBER_MEMBERSHIP_INFO: {
    PATH: '/padma-member/list/detail/:id/membership-info',
    HEADER_TITLE: "Detail",
    TITLE: 'Member Detail',
    TITLE_ALT: 'Member Detail',
    PERMISSION: 'padma_member.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_MEMBER_POINT_HISTORY: {
    PATH: '/padma-member/list/detail/:id/point-history',
    HEADER_TITLE: "Detail",
    TITLE: 'Member Detail',
    TITLE_ALT: 'Member Detail',
    PERMISSION: 'padma_member.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_MEMBER: {
    PATH: '/padma-member/list/edit',
    HEADER_TITLE: "Edit",
    TITLE: 'Edit Member',
    TITLE_ALT: 'Edit Member',
    PERMISSION: 'padma_member.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_MEMBER_CUSTOMER_DATA: {
    PATH: '/padma-member/list/edit/:id/customer-data',
    HEADER_TITLE: "Detail",
    TITLE: 'Edit Customer Data',
    TITLE_ALT: 'Edit Customer Data',
    PERMISSION: 'padma_member.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_MEMBER_MEMBERSHIP_INFO: {
    PATH: '/padma-member/list/edit/:id/membership-info',
    HEADER_TITLE: "Detail",
    TITLE: 'Edit Membership Info',
    TITLE_ALT: 'Edit Membership Info',
    PERMISSION: 'padma_member.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_MEMBER_POINT_HISTORY: {
    PATH: '/padma-member/list/edit/:id/point-history',
    HEADER_TITLE: "Detail",
    TITLE: 'Edit Point History',
    TITLE_ALT: 'Edit Point History',
    PERMISSION: 'padma_member.update',
    breadcrumbs: defaultBreadcrumbs,
  },

}

const padmaMemberBreadcrumbs = generateBreadcrumb([PadmaMemberScreens.LIST_MEMBER])
PadmaMemberScreens.DETAIL_MEMBER_CUSTOMER_DATA.breadcrumbs = () => padmaMemberBreadcrumbs
PadmaMemberScreens.DETAIL_MEMBER_MEMBERSHIP_INFO.breadcrumbs = () => padmaMemberBreadcrumbs
PadmaMemberScreens.DETAIL_MEMBER_POINT_HISTORY.breadcrumbs = () => padmaMemberBreadcrumbs
PadmaMemberScreens.EDIT_MEMBER_CUSTOMER_DATA.breadcrumbs = () => padmaMemberBreadcrumbs
PadmaMemberScreens.EDIT_MEMBER_MEMBERSHIP_INFO.breadcrumbs = () => padmaMemberBreadcrumbs
PadmaMemberScreens.EDIT_MEMBER_POINT_HISTORY.breadcrumbs = () => padmaMemberBreadcrumbs

export default PadmaMemberScreens
