import clsx from 'clsx'
import React, { FC } from 'react'
import { useLayout } from '../../../core/LayoutProvider'
import { usePageData } from '../../../core/PageData'

const DefaultTitle: FC = () => {
  // const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  // const {config, attributes, classes} = useLayout()
  const { pageTitle } = usePageData()
  const { attributes, classes } = useLayout()
  return (
    <div>
      <div
        {...attributes.pageTitle}
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        {/* begin::Title */}
        {pageTitle && (
          <h1 className='custom-default-title'>
            {pageTitle}
          </h1>
        )}
      </div>
    </div>
  )
}

export { DefaultTitle }
