import React, { useCallback } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import CustomModalHeader from 'src/app/components/custom-modal-header/CustomModalHeader'
import CustomButton from 'src/app/components/CustomButton'

interface Props {
  show: boolean
  handleSuccess: () => void
  handleClose: () => void
  processing: boolean
}

const DoubleLoginModal: React.FC<Props> = ({ show, handleSuccess, handleClose, processing }) => {
  const intl = useIntl()

  const closeFn = useCallback(() => {
    handleClose()
  }, [])

  return (
    <Modal aria-hidden='true' tabIndex='-1' show={show} onHide={closeFn} centered scrollable>
      <CustomModalHeader
        title={intl.formatMessage({ id: "ERROR.ACCOUNT_IN_USE" })}
        onClick={closeFn}
      />

      <div className='modal-body'>
        {intl.formatMessage({ id: "ERROR.ACCOUNT_IS_USED" })}
      </div>
      <div className='modal-footer'>
        <CustomButton
          variant='back'
          onClick={closeFn}
          className="mb-2 mb-md-0 me-md-4 w-100 w-md-150px"
        />
        <CustomButton
          variant='save'
          disabled={processing}
          processing={processing}
          tabIndex={3}
          type="button"
          data-test-id="button-ok"
          onClick={() => handleSuccess()}
          className="w-100 w-md-150px"
        >{intl.formatMessage({ id: "GENERAL.YES" })}</CustomButton>

      </div>
    </Modal >
  )
}

export default DoubleLoginModal
