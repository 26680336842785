/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import GuestWrapper from '../guest-wrapper/GuestWrapper'
import CreateNewPasswordForm from '../create-new-password-form/CreateNewPasswordForm'
import InfoCard from 'src/app/components/info-card/InfoCard'
import { useLocation } from 'react-router-dom'
import { useMobileAccount } from './MobileAccount.hook'
import { Type } from './MobileAccount.types'
import CreateNewPinForm from '../create-new-pin-form/CreateNewPinForm'

const MobileAccountPage = () => {
  let query = new URLSearchParams(useLocation().search)
  const type = query.get('type') as Type
  const state = useMobileAccount()
  if (!state.token) return null

  return (
    <GuestWrapper isMobile>
      <InfoCard
        variant={state.isError ? "warning" : "success"}
        isShow={state.isError !== null && state.responseCode !== "PADMA-VERIFY-EMAIL-TOKEN-MEMBER-200"}
        title={state.info?.title}
        showActionButton={false}
        desc={state.info?.desc}
      />
      <CreateNewPasswordForm
        formik={state.forgotPasswordFormik}
        isShow={type === "forgot-password" && state.showForm}
        loading={state.loading}
      />

      <CreateNewPinForm
        formik={state.forgotPinFormik}
        isShow={type === "forgot-pin" && state.showForm}
        loading={state.loading}
      />

    </GuestWrapper>
  )
}

export default MobileAccountPage