/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import LogoutModal from 'src/app/components/LogoutModal'

const HeaderUserMenu: FC = () => {
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false)
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary pt-4 pb-2 fs-6 w-200px mt-1'
        data-kt-menu='true'
        id='MyNavbarDropdown'
      >
        <div className='menu-item px-3 my-1'>
          <div onClick={() => setShowLogoutModal(true)} className='menu-link'>
            <span>
              <InlineSVG src='/media/icons/padma/IconSignOut.svg' />
            </span>
            <span className='ms-3'>Logout</span>
          </div>
        </div>
      </div>

      <LogoutModal
        show={showLogoutModal}
        handleClose={() => setShowLogoutModal(false)}
      />
    </>
  )
}

export { HeaderUserMenu }
