/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import AuthScreens from '../modules/auth/AuthScreens'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import LandingPage from '../modules/landing-page/pages/LandingPage'

const Routes: FC = () => {
  const accessToken: any = useSelector<RootState>(
    ({ auth }) => Boolean(auth.accessToken),
    shallowEqual
  )

  return (
    <Switch>
      <Route
        path={AuthScreens.VERIFICATION_SUCCESS.PATH}
        component={AuthScreens.VERIFICATION_SUCCESS.COMPONENT}
      />
      <Route
        path={AuthScreens.CREATE_PASSWORD.PATH}
        component={AuthScreens.CREATE_PASSWORD.COMPONENT}
      />
      <Route
        path={AuthScreens.MOBILE_ACCOUNT.PATH}
        component={AuthScreens.MOBILE_ACCOUNT.COMPONENT}
      />
      {!accessToken ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path='/auth'>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      <Route path='/landing-page' component={LandingPage} />


      {!accessToken ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}


    </Switch>
  )
}

export { Routes }
