/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthScreens from './AuthScreens'
import GuestWrapper from './partials/guest-wrapper/GuestWrapper'

export function AuthPage() {
  return (
    <GuestWrapper>
      <Switch>
        <Route path={AuthScreens.LOGIN_EMAIL.PATH} component={AuthScreens.LOGIN_EMAIL.COMPONENT} />
        <Route path={AuthScreens.FORGOT.PATH} component={AuthScreens.FORGOT.COMPONENT} />
        <Redirect from='/auth' exact={true} to='/auth/login' />
        <Redirect to='/auth/login' />
      </Switch>
    </GuestWrapper>
  )
}
