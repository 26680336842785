import { Mutex } from 'async-mutex'
import axios, { AxiosStatic } from 'axios'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'

const mutex = new Mutex()
const plainAxios = axios.create()

const checkErrorStatus = (errorCode: string): boolean => {
  switch (errorCode) {
    case "PADMA-CONFLICT-409":
    case "PADMA-UNAUTHORIZED-401":
      return true;
    default:
      return false;
  }
}

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL_AUTH
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL_AUTH

  localAxios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState()

      if (accessToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      config.headers['app-token'] = process.env.REACT_APP_TOKEN;

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )
  localAxios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const {
        auth: { refreshToken },
      } = store.getState()
      let error_code = error?.response?.data?.response_schema?.response_code
      const { dispatch } = store
      const isError = checkErrorStatus(error_code)

      if (isError && refreshToken != null) {
        return mutex.runExclusive(async () => {
          const refresh = await _refreshToken(refreshToken)

          if (refresh) {
            error.config.headers.Authorization = `Bearer ${store.getState().auth.accessToken}`
            try {
              return await plainAxios.request(error.config)
            } catch (err: any) {
              if (err.response?.status === 401) dispatch(AuthRedux.actions.logout())
              throw err
            }
          }
          dispatch(AuthRedux.actions.logout())
          throw error
        })
      }
      throw error
    }
  )
  const _refreshToken = async (refreshToken: any) => {
    const { dispatch } = store
    try {
      const result = await plainAxios.get(`/v1/cms/refresh-token`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      if (result.data)
        dispatch(
          AuthRedux.actions.fulfillToken({
            token: result.data.response_output?.detail.access_token,
            refreshToken: refreshToken,
          })
        )
      return result.data
    } catch (error) {
      dispatch(AuthRedux.actions.logout())
      throw error
    }
  }
}
