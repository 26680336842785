import clsx from 'clsx';
import React, { FC, HTMLProps } from 'react'
import { blockInvalidChar } from '../utils/input-utils';

interface CustomInputTextProps extends HTMLProps<HTMLInputElement> {
    label?: string;
    isError: boolean;
    errorMessage?: string;
    containerClassName?: string;
    suffix?: string;
    preffix?: string;
}

const CustomInputText: FC<CustomInputTextProps> = ({
    label,
    isError,
    errorMessage,
    required,
    className,
    containerClassName,
    suffix,
    preffix,
    type = "text",
    ...props
}) => {
    return (
        <div className={containerClassName}>
            {label && (
                <label className='flex-fill form-label fs-6 text-black'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <div className='position-relative d-flex'>
                <input
                    className={clsx("form-control form-control-lg form-control-solid", { "invalid-form": isError }, { "ps-16": preffix }, { "pe-16": suffix }, className)}
                    style={{ borderRadius: '8px' }}
                    type={type}
                    {...(type === "number" ? { onKeyDown: blockInvalidChar } : {})}
                    {...props}
                />

                {suffix && <div className='suffix h-44px w-60px position-absolute end-0 z-index-1 d-flex align-items-center justify-content-center'>
                    {suffix}
                </div>}

                {preffix && <div className='preffix h-44px w-60px position-absolute start-0 z-index-1 d-flex align-items-center justify-content-center'>
                    {preffix}
                </div>}
            </div>
            {isError && (
                <div
                    data-test-id='error-validation-alert-of-name'
                    className='fv-plugins-message-container text-danger mt-2'
                >
                    <span role='alert'>{errorMessage}</span>
                </div>
            )}
        </div>
    )
}

export default CustomInputText