import { CreateNewPassword } from './partials/create-new-password/CreateNewPassword'
import { ForgotPassword } from './partials/forgot-password/ForgotPassword'
import { Login } from './partials/login/Login'
import MobileAccountPage from './partials/mobile-account/MobileAccount.page'
import VerificationSuccess from './partials/verification-success/VerificationSuccess'

const AuthScreens = {
  LOGIN_EMAIL: {
    COMPONENT: Login,
    PATH: '/auth/login',
  },
  FORGOT: {
    COMPONENT: ForgotPassword,
    PATH: '/auth/forgot-password',
  },
  CREATE_PASSWORD: {
    COMPONENT: CreateNewPassword,
    PATH: '/auth/create-password',
  },
  VERIFICATION_SUCCESS: {
    COMPONENT: VerificationSuccess,
    PATH: '/auth/verification-success',
  },
  MOBILE_ACCOUNT: {
    COMPONENT: MobileAccountPage,
    PATH: '/auth/mobile-account',
  }
}

export default AuthScreens
