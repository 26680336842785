import { FormikProps } from 'formik'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from "react-router-dom";
import CustomButton from 'src/app/components/CustomButton'
import CustomInputText from 'src/app/components/CustomInputText';
import FormatedParagraph from 'src/app/components/formated-paragraph/FormatedParagraph';
import InfoCardAlert from 'src/app/components/info-card-alert/InfoCardAlert';
import AuthScreens from '../../AuthScreens';
import { ForgotPasswordInitialValues } from '../../hooks/forgot-password-hook'

export interface ForgotPasswordFormProps {
    isShow: boolean;
    formik: FormikProps<ForgotPasswordInitialValues>,
    hasErrors?: boolean,
    loading: boolean,
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ isShow, formik, hasErrors, loading }) => {
    const intl = useIntl()
    const history = useHistory()

    if (!isShow) return null

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='forgot-password-form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-black mb-3'>{intl.formatMessage({ id: "FORGOT_PASSWORD.FORGOT_PASSWORD" })}</h1>
                <div className='text-gray-700 fs-5 w-400px mx-auto'>
                    <FormatedParagraph>{intl.formatMessage({ id: "FORGOT_PASSWORD.ENTER_REGISTERED_EMAIL" })}</FormatedParagraph>
                </div>
            </div>

            <InfoCardAlert
                isShow={hasErrors === true}
                variant="warning"
                title={formik.status}
            />

            <div className='fv-row mb-8'>
                <CustomInputText
                    {...formik.getFieldProps('email')}
                    isError={(formik.touched.email && formik.errors.email) ? true : false}
                    errorMessage={formik.errors.email}
                    type='email'
                    name='email'
                    placeholder={intl.formatMessage({ id: "GENERAL.PLACEHOLDER" }, { name: "email" })}
                    autoComplete='off'
                    tabIndex={1}
                    id={'forgot-password-email'}
                    maxLength={50}
                    label={intl.formatMessage({ id: "FORGOT_PASSWORD.EMAIL" })}
                />
            </div>

            <div className='d-flex justify-content-center pb-lg-0'>
                <div className='text-right me-4'>
                    <CustomButton
                        id='forgot-password-back'
                        variant='back'
                        className='w-150px'
                        onClick={() => history.push(AuthScreens.LOGIN_EMAIL.PATH)}
                        disabled={formik.isSubmitting}
                    />
                </div>
                <div>
                    <CustomButton
                        variant='save'
                        disabled={formik.isSubmitting}
                        tabIndex={3}
                        className="w-150px"
                        id="forgot-password-submit"
                        processing={loading}
                    >{intl.formatMessage({ id: "FORGOT_PASSWORD.SEND_EMAIL" })}</CustomButton>

                </div>
            </div>
        </form>
    )
}

export default ForgotPasswordForm