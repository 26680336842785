import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const LoyaltyInfoScreens = {
    LIST_LOYALTY_INFO: {
        PATH: '/loyalty-info/list',
        HEADER_NAME: 'Loyalty Info',
        TITLE: 'List Membership Tier',
        TITLE_ALT: 'List Membership Tier',
        PERMISSION: 'loyalty_info.read',
        breadcrumbs: defaultBreadcrumbs,
    },
    ADD_LOYALTY_INFO: {
        PATH: '/loyalty-info/list/add',
        TITLE: 'Add',
        TITLE_ALT: 'Add',
        PERMISSION: 'loyalty_info.create',
        breadcrumbs: defaultBreadcrumbs,
    },
    DETAIL_LOYALTY_INFO: {
        PATH: '/loyalty-info/list/detail/:id',
        TITLE: 'Detail',
        TITLE_ALT: 'Detail',
        PERMISSION: 'loyalty_info.read',
        breadcrumbs: defaultBreadcrumbs,
    },
    EDIT_LOYALTY_INFO: {
        PATH: '/loyalty-info/list/edit/:id',
        TITLE: 'Edit Loyalty Info',
        TITLE_ALT: 'Edit Loyalty Info',
        PERMISSION: 'loyalty_info.update',
        breadcrumbs: defaultBreadcrumbs,
    },

}

const loyaltyInfoBreadcrumbs = generateBreadcrumb([LoyaltyInfoScreens.LIST_LOYALTY_INFO])
LoyaltyInfoScreens.DETAIL_LOYALTY_INFO.breadcrumbs = () => loyaltyInfoBreadcrumbs


export default LoyaltyInfoScreens
