import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const ReportsScreens = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Reports',
    TITLE_ALT: 'Reports',
    PERMISSION: 'reports.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_BOOKING_REPORT: {
    PATH: '/report/booking/list',
    HEADER_NAME: 'List Booking',
    TITLE: 'Booking Report',
    TITLE_ALT: 'Booking Report',
    PERMISSION: 'booking_report.read',
  },
  DETAIL_BOOKING_REPORT: {
    PATH: '/report/booking/list/detail/:booking_id',
    HEADER_NAME: 'Detail',
    TITLE: 'Booking Report Detail',
    TITLE_ALT: 'Detail',
    PERMISSION: 'booking_report.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_BOOKING_REPORT_POLICY: {
    PATH: '/report/booking/list/detail/:booking_id/policy',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Booking Report Policy',
    TITLE_ALT: 'Policy',
    PERMISSION: 'booking_report.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  LIST_AVAILABLE_ROOM: {
    PATH: '/report/booking/list/detail/:booking_id/reschedule/list',
    HEADER_NAME: 'Reschedule',
    TITLE: 'Reschedule Booking',
    TITLE_ALT: 'Reschedule Booking',
    PERMISSION: 'booking_report.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  RESCHEDULE: {
    PATH: '/report/booking/list/detail/:booking_id/reschedule/list/edit/:rate_plan_policy_id',
    HEADER_NAME: 'Reschedule',
    TITLE: 'Reschedule Booking',
    TITLE_ALT: 'Reschedule Booking',
    PERMISSION: 'booking_report.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  LIST_QUEUE_REPORT: {
    PATH: '/report/queue-report/list',
    HEADER_NAME: 'List Queue',
    TITLE: 'Queue Report',
    TITLE_ALT: 'Queue Report',
    PERMISSION: 'queue_report.read',
  },
  LIST_ABANDONED_BOOKING_REPORT: {
    PATH: '/report/abandoned-booking-report/list',
    HEADER_NAME: 'List Abandoned Booking',
    TITLE: 'Abandoned Booking Report',
    TITLE_ALT: 'Abandoned Booking Report',
    PERMISSION: 'abandoned_booking_report.read',
  },
}

export default ReportsScreens
