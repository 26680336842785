import { FormikProps } from 'formik';
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl';
import InfoCardAlert from 'src/app/components/info-card-alert/InfoCardAlert';
import PinInput from '../pin-input/PinInput';

export interface CreateNewPinFormProps {
    isShow: boolean;
    formik: FormikProps<{ pin: string, confirm_pin: string }>,
    loading: boolean,
}

const CreateNewPinForm: FC<CreateNewPinFormProps> = ({ isShow, formik, loading }) => {
    const intl = useIntl()
    const [step, setStep] = useState<number>(0)

    if (!isShow) return null

    return (
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>{intl.formatMessage({ id: "MOBILE_FORGOT_PIN.FORGOT_PIN.TITLE" })}</h1>
                <p className='text-dark'>{intl.formatMessage({ id: "MOBILE_FORGOT_PIN.FORGOT_PIN.DESCRIPTION" })}</p>
            </div>

            <InfoCardAlert
                isShow={Boolean(formik.status)}
                variant="warning"
                title={formik.status}
            />

            {
                step === 0 && (
                    <div className='fv-row mb-8 text-center'>
                        <p className='text-dark mb-3'>{intl.formatMessage({ id: "MOBILE_FORGOT_PIN.FORGOT_PIN.ENTER_YOUR_PIN" })}</p>
                        <PinInput
                            containerClassName='mb-8'
                            value={formik?.values.pin}
                            onChange={(value) => formik?.setFieldValue('pin', value)}
                            isError={!!(formik.touched.pin && formik.errors.pin)}
                            errorMessage={formik.errors.pin}
                            clear={true}
                            onComplete={() => setStep(1)}
                        />
                    </div>
                )
            }

            {
                step === 1 && (
                    <div className='fv-row text-center'>
                        <p className='text-dark mb-3'>{intl.formatMessage({ id: "MOBILE_FORGOT_PIN.FORGOT_PIN.REENTER_YOUR_PIN" })}</p>
                        <PinInput
                            containerClassName='mb-8'
                            value={formik?.values.confirm_pin}
                            onChange={(value) => formik?.setFieldValue('confirm_pin', value)}
                            isError={!!(formik.touched.pin && formik.errors.confirm_pin)}
                            errorMessage={formik.errors.confirm_pin}
                            clear={true}
                            onComplete={(value) => formik.handleSubmit()}
                        />
                    </div>
                )
            }


        </form>
    )
}

export default CreateNewPinForm