import clsx from 'clsx'
import React, { FC } from 'react'
import _camelCase from "lodash/camelCase"
import _replace from "lodash/replace"
import _startCase from "lodash/startCase"
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/setup'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const { config } = useLayout()
  const name: any = useSelector<RootState>(({ auth }) => auth?.user?.name, shallowEqual)
  const role_name: any = useSelector<RootState>(({ auth }) => auth?.user?.role_name, shallowEqual)

  return (
    <div className='d-flex align-items-stretch'>
      <div className='d-flex justify-content-center align-items-center'>

        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx(
              'cursor-pointer symbol',
              toolbarUserAvatarHeightClass,
              'd-flex align-items-center flex-shrink-1'
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <div
              className='d-flex justify-content-center align-items-center px-5 bg-white'
              style={{ height: '40px', borderRadius: 10 }}
            >
              <div
                className='bg-matte-200 me-3'
                style={{ width: '24px', height: '24px', borderRadius: '50%' }}
              ></div>
              <div className='me-4 d-flex flex-column justify-content-center '>
                <span className='me-3 fw-semibold text-neutral-500 d-block fs-7 lh-base'>
                  {name ?? ''}
                </span>
                <span className='me-3 text-muted fs-8 lh-1'>
                  {_startCase(_camelCase(_replace(role_name, "_", ' '))) ?? ''}
                </span>
              </div>
              <div>
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr071.svg'}
                  className={'svg-icon-1 rotate-180'}
                />
              </div>
            </div>
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
