/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import CustomModalHeader from './custom-modal-header/CustomModalHeader'
import { useIntl } from 'react-intl'
import CustomButton from './CustomButton'
import { useLogout } from '../modules/auth/hooks/logout-hooks'

type Props = {
  show: boolean
  handleClose: () => void
}

const LogoutModal: React.FC<Props> = ({ show, handleClose }) => {
  const intl = useIntl()
  const { loggingOut, doLogout } = useLogout()

  return (
    <Modal aria-hidden='true' tabIndex='-1' show={show} onHide={handleClose} centered>
      <CustomModalHeader
        title={intl.formatMessage({ id: "LOGOUT.LOGOUT" })}
        onClick={handleClose}
      />

      <div className='modal-body'>
        <div className='fv-row'>
          <div className='fs-5'>{intl.formatMessage({ id: "FORGOT_PASSWORD.LOGOUT_CONFIRMATION" })}</div>
        </div>
      </div>
      <div className='modal-footer'>
        <CustomButton
          variant='back'
          onClick={handleClose}
          className="me-4 w-150px"
          disabled={loggingOut}
        />
        <CustomButton
          variant='save'
          disabled={loggingOut}
          processing={loggingOut}
          tabIndex={3}
          type="button"
          data-test-id="button-ok"
          onClick={doLogout}
          className="w-150px"
        >{intl.formatMessage({ id: "GENERAL.YES" })}</CustomButton>
      </div>
    </Modal>
  )
}

export default LogoutModal
