import React from 'react'
import Header from '../partials/header/Header'
import Content from '../partials/content/Content'
import Shapes from '../partials/shapes/Shapes'
import Phone from '../partials/phone/Phone'

type Props = {}

const LandingPage = (props: Props) => {
    return (
        <div className='bg-gradient-1 min-h-100 d-flex flex-column position-relative justify-content-center'>
            <Header />
            <div className='flex-equal d-flex align-items-center'>
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-lg-6 mb-20 mb-md-10">
                            <Content />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className='h-246px h-md-400px d-flex align-items-center justify-content-center position-relative'>
                                <Shapes />
                                <Phone />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage