/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import GuestWrapper from '../guest-wrapper/GuestWrapper'
import { useCreateNewPassword } from '../../hooks/create-new-password-hook'
import CreateNewPasswordForm from '../create-new-password-form/CreateNewPasswordForm'
import InfoCard from 'src/app/components/info-card/InfoCard'
import { useLocation } from 'react-router-dom'


export function CreateNewPassword() {
  const { formik, loading, backToLogin, responseCode, responseMessage, token, intl } = useCreateNewPassword()
  let query = new URLSearchParams(useLocation().search)
  const type = query.get('type') as string
  if (!token) return null

  return (
    <GuestWrapper>

      {
        type === "account-verification" && (
          <InfoCard
            variant='success'
            isShow={responseCode === "PADMA-CHANGE-PASSWORD-200"}
            title={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.IS_VERIFIED" })}
            buttonLabel={intl.formatMessage({ id: "LOGIN.LOGIN" })}
            onClick={backToLogin}
            desc={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.IS_VERIFIED_MESSAGE" })}
          />
        )
      }

      {
        type === "reset-password" && (
          <InfoCard
            variant='success'
            isShow={responseCode === "PADMA-CHANGE-PASSWORD-200"}
            title={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.PASSWORD_UPDATED" })}
            buttonLabel={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.BACK_TO_LOGIN" })}
            onClick={backToLogin}
            desc={responseMessage}
          />
        )
      }

      {
        type === "forgot-password" && (
          <InfoCard
            variant='success'
            isShow={responseCode === "PADMA-CHANGE-PASSWORD-200"}
            title={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.PASSWORD_UPDATED" })}
            buttonLabel={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.BACK_TO_LOGIN" })}
            onClick={backToLogin}
            desc={responseMessage}
          />
        )
      }

      <InfoCard
        variant='warning'
        isShow={responseCode === "PADMA-CHANGE-PASSWORD-400" || responseCode === "PADMA-CHANGE-PASSWORD-410"}
        title={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.UNABLE_RESET_PASSWORD" })}
        buttonLabel={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.BACK_TO_LOGIN" })}
        onClick={backToLogin}
        desc={responseMessage}
      />

      <InfoCard
        variant='success'
        isShow={responseCode === "PADMA-CHANGE-PASSWORD-202"}
        title={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.EMAIL_SENT" })}
        buttonLabel={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.BACK_TO_LOGIN" })}
        onClick={backToLogin}
        desc={responseMessage}
      />

      <CreateNewPasswordForm
        formik={formik}
        isShow={responseCode === undefined}
        loading={loading}
      />

    </GuestWrapper>
  )
}
