import clsx from 'clsx'
import React, { HTMLProps, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import CustomFormLabel from './CustomFormLabel'

interface InputPasswordProps extends HTMLProps<HTMLInputElement> {
  forgotlink?: string
  requiredPass?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const InputPassword = ({
  requiredPass,
  onChange,
  isError,
  errorMessage,
  className,
  ...props }: InputPasswordProps) => {
  const [visible, setVisible] = useState(false)
  const intl = useIntl()
  const labelPassword = intl.formatMessage({ id: "LOGIN.PASSWORD" })

  return (
    <>
      <div className='d-flex'>
        {requiredPass ? (
          <CustomFormLabel title={props.label ? props.label : labelPassword} required />
        ) : (
          <label className='flex-fill form-label fs-6 text-black'>
            {props.label ? props.label : labelPassword}
          </label>
        )}
        {props.forgotlink && (
          <Link to={props.forgotlink} className='fs-6 fw-medium link-primary' tabIndex={5}>
            {intl.formatMessage({ id: "FORGOT_PASSWORD.FORGOT_PASSWORD" })}
          </Link>
        )}
      </div>
      <div className='position-relative'>
        <input
          placeholder={props.placeholder || 'Masukkan password disini'}
          type={visible ? 'text' : 'password'}
          name='password'
          className={clsx('form-control form-control-lg form-control-solid', { 'invalid-form': isError }, className)}
          style={{ paddingRight: 42, borderRadius: 8 }}
          autoComplete='off'
          onChange={(e) => {
            if (e.target.value) {
              const start = e.target.selectionStart
              const end = e.target.selectionEnd
              const len = e.target.value.length
              e.target.value = e.target.value.replace(/\s/g, '')
              const diff = len - e.target.value.length
              if (start !== null && end !== null)
                e.target.setSelectionRange(start - diff, end - diff)
            }
            if (onChange) onChange(e)
          }}
          {...props}
        />
        <InlineSVG
          src={`/media/icons/padma/${visible ? 'IconEyeOn' : 'IconEyeOff'}.svg`}
          className='position-absolute'
          style={{ right: 18, top: 15, cursor: 'pointer' }}
          onClick={() => setVisible(!visible)}
        />
      </div>

      {isError ? (
        <div className='fv-plugins-message-container text-danger mt-2'>
          <span role='alert'>{errorMessage}</span>
        </div>
      ) : null
      }
    </>
  )
}

export default InputPassword
