import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const PromotionBannerScreens = {
  LIST_PROMOTION_BANNER: {
    PATH: '/promotion-banner',
    HEADER_NAME: 'Promotion Banner',
    TITLE: 'List Promotion Banner',
    TITLE_ALT: 'List Promotion Banner',
    PERMISSION: 'list_promotion_banner.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_PROMOTION_BANNER: {
    PATH: '/promotion-banner/:banner_type/list/add',
    TITLE: 'Add Banner',
    TITLE_ALT: 'Add Banner',
    PERMISSION: 'list_promotion_banner.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_PROMOTION_BANNER: {
    PATH: '/promotion-banner/:banner_type/list/detail/:id',
    TITLE: 'Detail Promotion Banner',
    TITLE_ALT: 'Detail Promotion Banner',
    PERMISSION: 'list_promotion_banner.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_PROMOTION_BANNER: {
    PATH: '/promotion-banner/:banner_type/list/edit/:id',
    TITLE: 'Update Promotion Banner',
    TITLE_ALT: 'Update Promotion Banner',
    PERMISSION: 'list_promotion_banner.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const promotionBannerBreadcrumbs = generateBreadcrumb([
  PromotionBannerScreens.LIST_PROMOTION_BANNER,
])

PromotionBannerScreens.ADD_PROMOTION_BANNER.breadcrumbs = () => promotionBannerBreadcrumbs
PromotionBannerScreens.DETAIL_PROMOTION_BANNER.breadcrumbs = () => promotionBannerBreadcrumbs
PromotionBannerScreens.EDIT_PROMOTION_BANNER.breadcrumbs = () => promotionBannerBreadcrumbs

export default PromotionBannerScreens
