import {
  Action,
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
  Middleware,
  Reducer,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { reduxBatch } from '@manaflair/redux-batch'
import { Persistor, persistStore } from 'redux-persist'
import { rootReducer, rootSaga } from './RootReducer'

const sagaMiddleware = createSagaMiddleware()
const appMiddleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
]

const extractStoreType = function <S, A extends Action, M extends ReadonlyArray<Middleware<{}, S>>>(
  reducer: (...args: any) => Reducer<S, A>,
  middleware: M
): EnhancedStore<S, A, M> {
  return null as unknown as EnhancedStore<S, A, M>
}

let _store = extractStoreType(rootReducer, appMiddleware)
const store = () => {
  if (!_store) {
    _store = configureStore({
      reducer: rootReducer(),
      middleware: appMiddleware,
      devTools: process.env.NODE_ENV !== 'production',
      enhancers: [reduxBatch],
    })
    sagaMiddleware.run(rootSaga)
  }

  return _store
}

export type AppDispatch = typeof _store.dispatch

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
let _persistor: Persistor | null = null
export const persistor = () => {
  if (!_persistor && _store) _persistor = persistStore(_store)
  return _persistor!
}

export default store
