import { FormikProps } from 'formik';
import React, { FC } from 'react'
import { useIntl } from 'react-intl';
import CustomButton from 'src/app/components/CustomButton';
import InfoCardAlert from 'src/app/components/info-card-alert/InfoCardAlert';
import InputPassword from 'src/app/components/InputPassword';

export interface CreateNewPasswordFormProps {
    isShow: boolean;
    formik: FormikProps<{ new_password: string, confirm_password: string }>,
    loading: boolean,
}

const CreateNewPasswordForm: FC<CreateNewPasswordFormProps> = ({ isShow, formik, loading }) => {
    const intl = useIntl()

    if (!isShow) return null

    return (
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.CREATE_NEW_PASSWORD" })}</h1>
            </div>

            <InfoCardAlert
                isShow={Boolean(formik.status)}
                variant="warning"
                title={formik.status}
            />

            <div className='fv-row mb-8'>
                <InputPassword
                    {...formik.getFieldProps('new_password')}
                    label={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.NEW_PASSWORD" })}
                    id={'create-new-password-new-password'}
                    minLength={8}
                    placeholder={intl.formatMessage({ id: "GENERAL.PLACEHOLDER" }, { name: "password" })}
                    isError={(formik.touched.new_password && formik.errors.new_password) ? true : false}
                    errorMessage={formik.errors.new_password}
                />
            </div>

            <div className='fv-row mb-8'>
                <InputPassword
                    {...formik.getFieldProps('confirm_password')}
                    label={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.CONFIRM_PASSWORD" })}
                    id={'create-new-password-confirm-password'}
                    minLength={8}
                    placeholder={intl.formatMessage({ id: "GENERAL.PLACEHOLDER" }, { name: "new password" })}
                    isError={(formik.touched.confirm_password && formik.errors.confirm_password) ? true : false}
                    errorMessage={formik.errors.confirm_password}
                />
            </div>

            <div className='text-center'>
                <CustomButton
                    variant='save'
                    className='action-button'
                    disabled={formik.isSubmitting}
                    id="create-new-password-submit"
                    processing={loading}
                >{intl.formatMessage({ id: "GENERAL.SAVE" })}</CustomButton>

            </div>
        </form>
    )
}

export default CreateNewPasswordForm