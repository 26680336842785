import React from 'react'
import clsx from "clsx";

type Props = {
  title: string | undefined
  required?: boolean
  opsional?: boolean;
  className?: string
  detail?: boolean;
  handleDetail?: (value: any) => void
}

const CustomFormLabel: React.FC<Props> = ({ title, required, opsional, className, detail = false, handleDetail }) => {
  return (
    <label className={clsx("flex-fill form-label fs-6 text-black", className)} style={{ marginBottom: "0.5rem" }}>
      {title}
      {required && <span className='text-danger'>*</span>}
      {opsional && <span className='text-gray-400'>(Opsional)</span>}
      {detail && <span className='text-detail cursor-pointer' onClick={handleDetail}> <u className='fw-normal text-blue-60'>See details</u></span>}
    </label>
  )
}

export default CustomFormLabel
