import clsx from 'clsx'
import { HTMLAttributes } from 'enzyme'
import React, { FC } from 'react'

const FormatedParagraph: FC<HTMLAttributes> = ({ className, children, ...props }) => {
    return (
        <p className={clsx('mb-0 white-space-preline', className)} {...props} >{children}</p>
    )
}

export default FormatedParagraph