import React from 'react'
import { Link } from 'react-router-dom'
import InlineSVG from 'react-inlinesvg/esm'

type Props = {}

const Content = (props: Props) => {

    return (
        <div className='h-100 d-flex flex-column justify-content-center  align-items-center align-items-lg-start'>
            <h1 className='display-5 display-lg-4 text-white text-center text-lg-start mb-5'>Register Now, <br /> Get Extra Benefit!</h1>
            <p className='fs-6 fs-lg-4 text-white  mb-2'>Download disini</p>
            <div className='d-flex'>
                <Link to={process.env.PLAY_STORE_LINK || ""} className='me-5'>
                    <InlineSVG src={'/media/icons/padma/googleplay_badge.svg'} className='w-80px w-md-120px' />
                </Link>
                <Link to={process.env.APP_STORE_LINK || ""}>
                    <InlineSVG src={'/media/icons/padma/appstore_badge.svg'} className='w-80px w-md-120px' />
                </Link>
            </div>
        </div>
    )
}

export default Content