import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'

type Props = {}

const Shapes = (props: Props) => {
    return (
        <div
            className='position-absolute spin'
            style={{ animationDuration: "20s" }}
        >
            <InlineSVG
                src={'/media/icons/padma/landing-page-shape-1.svg'}
                className='w-202px w-lg-396px position-absolute translate-middle '
            />
            <InlineSVG
                src={'/media/icons/padma/landing-page-shape-2.svg'}
                className='w-210px w-lg-412px position-absolute translate-middle'
            />
            <InlineSVG
                src={'/media/icons/padma/landing-page-shape-3.svg'}
                className='w-202px w-lg-396px position-absolute translate-middle'

            />
        </div>
    )
}

export default Shapes