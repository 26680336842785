import clsx from 'clsx'
import React, { ButtonHTMLAttributes, FC } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant:
  | 'back'
  | 'back-primary'
  | 'save'
  | 'close'
  | 'primary'
  | 'primary-outline'
  | 'secondary'
  | 'tertiary'
  | 'info'
  | 'light-info'
  | 'danger'
  | 'light'
  | 'light-2'
  | 'delete'
  | 'cancel'
  | 'yes'
  | 'no'
  | 'continue'
  | 'property-submenu'
  | 'icon-delete'
  | 'icon-edit'
  | 'icon-detail'
  | 'icon-add'
  | 'icon-add-large'
  | 'icon-delete-large'
  | 'icon-prev'
  | 'icon-next'
  | 'icon-resend'
  | 'download'
  | 'download-pdf'
  | 'cancel-booking'
  | 'reschedule-booking'
  | 'icon-reset-password'
  | 'disable-white'

  processing?: boolean
}

const CustomButton: FC<CustomButtonProps> = ({
  variant,
  processing = false,
  children,
  className,
  ...props
}) => {
  const intl = useIntl()

  switch (variant) {
    case 'primary':
      return (
        <button type='button' className={clsx('btn btn-primary', className)} {...props}>
          {children}
        </button>
      )
    case 'primary-outline':
      return (
        <button type='button' className={clsx('btn btn-outline-primary text-black', className)} {...props}>
          {children}
        </button>
      )

    case 'secondary':
      return (
        <button type='button' className={clsx('btn btn-secondary', className)} {...props}>
          {children}
        </button>
      )

    case 'tertiary':
      return (
        <button type='button' className={clsx('btn btn-tertiary', className)} {...props}>
          {children}
        </button>
      )

    case 'info':
      return (
        <button type='button' className={clsx('btn btn-info', className)} {...props}>
          {children}
        </button>
      )

    case 'light-info':
      return (
        <button
          type='button'
          className={clsx('btn btn-light-info text-white', className)}
          {...props}
        >
          {children}
        </button>
      )

    case 'danger':
      return (
        <button type='button' className={clsx('btn btn-danger', className)} {...props}>
          {children}
        </button>
      )
    case 'light':
      return (
        <button type='button' className={clsx('btn btn-light', className)} {...props}>
          {children}
        </button>
      )
    case 'light-2':
      return (
        <button type='button' className={clsx('btn btn-light-2', className)} {...props}>
          {children}
        </button>
      )

    case 'close':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG
            src='/media/icons/padma/Close.svg'
            className='w-12px h-12px text-neutral-200'
          />
        </button>
      )

    case 'back':
      return (
        <button
          type='button'
          className={clsx('btn btn-sm btn-secondary w-150px', className)}
          {...props}
        >
          {children || intl.formatMessage({ id: 'GENERAL.BACK' })}
        </button>
      )

    case 'back-primary':
      return (
        <button
          type='button'
          className={clsx('btn btn-sm btn-primary w-150px', className)}
          {...props}
        >
          {children || intl.formatMessage({ id: 'GENERAL.BACK' })}
        </button>
      )

    case 'cancel':
      return (
        <button
          type='button'
          className={clsx('btn btn-sm btn-secondary', className || 'w-150px')}
          {...props}
        >
          {children || intl.formatMessage({ id: 'GENERAL.CANCEL' })}
        </button>
      )

    case 'save':
      return (
        <button
          type='submit'
          className={clsx('btn btn-sm btn-primary', className || 'w-150px')}
          {...props}
        >
          {!processing && (
            <span className='indicator-label'>
              {children || intl.formatMessage({ id: 'GENERAL.SAVE' })}
            </span>
          )}
          {processing && (
            <span className='indicator-label'>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      )

    case 'delete':
      return (
        <button type='button' className={clsx('btn btn-sm btn-secondary', className)} {...props}>
          {!processing && (
            <span className='indicator-label'>
              {children || intl.formatMessage({ id: 'GENERAL.DELETE' })}
            </span>
          )}
          {processing && (
            <span className='indicator-label'>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      )

    case 'icon-edit':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconEdit.svg' className='w-24px h-24px' />
        </button>
      )

    case 'icon-detail':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconView.svg' className='w-24px h-24px' />
        </button>
      )

    case 'icon-delete':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconDelete.svg' className='w-24px h-24px' />
        </button>
      )
    case 'icon-add':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconAdd.svg' className='w-24px h-24px' />
        </button>
      )

    case 'icon-add-large':
      return (
        <button type='button' className={clsx('btn-icon-large', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconAddLarge.svg' className='w-44px h-44px' />
        </button>
      )

    case 'icon-delete-large':
      return (
        <button type='button' className={clsx('btn-icon-large', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconDeleteLarge.svg' className='w-44px h-44px' />
        </button>
      )

    case 'icon-prev':
      return (
        <button type='button' className={clsx('btn-icon-prev', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconPrev.svg' />
        </button>
      )

    case 'icon-next':
      return (
        <button type='button' className={clsx('btn-icon-next', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconNext.svg' />
        </button>
      )
    case 'icon-resend':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconResend.svg' />
        </button>
      )

    case 'yes':
      return (
        <button
          type='button'
          className={clsx('btn btn-sm btn-primary w-100 w-md-150px', className)}
          {...props}
        >
          {children || intl.formatMessage({ id: 'GENERAL.YES' })}
        </button>
      )

    case 'no':
      return (
        <button
          type='button'
          className={clsx('btn btn-sm btn-secondary w-100 w-md-150px', className)}
          {...props}
        >
          {children || intl.formatMessage({ id: 'GENERAL.NO' })}
        </button>
      )

    case 'continue':
      return (
        <button
          type='button'
          className={clsx('btn btn-sm btn-primary w-150px', className)}
          {...props}
        >
          {children || intl.formatMessage({ id: 'GENERAL.CONTINUE' })}
        </button>
      )

    case 'property-submenu':
      return (
        <button
          type='button'
          className={clsx('btn btn-md btn-property-submenu w-100', className)}
          {...props}
        >
          {children}
        </button>
      )
    case 'download':
      return (
        <button type='button' className={clsx('btn btn-sm btn-primary', className)} {...props}>
          <img
            alt='Logo'
            className=' w-24px me-3'
            src={toAbsoluteUrl('/media/icons/padma/IconDownload.png')}
          />
          {children}
        </button>
      )
    case 'download-pdf':
      return (
        <button type='button' className={clsx('btn btn-sm btn-secondary', className)} {...props}>
          <img
            alt='Logo'
            className=' w-24px me-3'
            src={toAbsoluteUrl('/media/icons/padma/IconPdf.png')}
          />
          {children}
        </button>
      )

    case 'cancel-booking':
      return (
        <button type='button' className={clsx('btn btn-sm btn-danger w-140px', className)} {...props}>
          {intl.formatMessage({ id: "GENERAL.CANCEL" })}
        </button>
      )

    case 'reschedule-booking':
      return (
        <button type='button' className={clsx('btn btn-sm btn-light-orange w-140px', className)} {...props}>
          {intl.formatMessage({ id: "GENERAL.RESCHEDULE" })}
        </button>
      )
    case 'icon-reset-password':
      return (
        <button type='button' className={clsx('btn btn-sm btn-icon', className)} {...props}>
          <InlineSVG src='/media/icons/padma/IconResetPassword.svg' />
        </button>
      )
    case 'disable-white':
        return (
          <button type='button' disabled className={clsx('btn btn-disabled', className)} {...props}>
            {children}
          </button>
        )

    default:
      return <button {...props}>{children}</button>
  }
}

export default CustomButton
