import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { toAbsoluteUrl } from 'src/_metronic/helpers'

type Props = {}

const Phone = (props: Props) => {
    return (
        <div className='position-absolute'>
            <div className='position-relative'>
                <InlineSVG src={'/media/icons/padma/ornament-1.svg'} className='w-12px w-xl-20px position-absolute text-white top-0 opacity-50' style={{ left: 0 }} />
                <InlineSVG src={'/media/icons/padma/ornament-2.svg'} className='w-20px  w-xl-24px position-absolute text-white mt-5 ' style={{ left: 15, top: 10 }} />
                <InlineSVG src={'/media/icons/padma/ornament-1.svg'} className='w-12px w-xl-20px position-absolute text-white top-25 opacity-50' style={{ left: 15 }} />
                <InlineSVG src={'/media/icons/padma/ornament-1.svg'} className='w-12px w-xl-20px position-absolute text-white top-50' style={{ right: -30 }} />
                <img
                    alt='phone mockuo'
                    className='h-300px h-md-400px h-lg-500px h-xl-600px'
                    src={toAbsoluteUrl('/media/icons/padma/phone-mockup.png')}
                />
            </div>
        </div>
    )
}

export default Phone