import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/setup'
import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  const logo = useSelector(
    ({ auth }: RootState) =>
      auth?.user?.partners?.logo?.startsWith('http') ? auth?.user?.partners?.logo : undefined,
    shallowEqual
  )
  return (
    <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
      <img
        className='mb-4'
        src={logo ?? toAbsoluteUrl('/LogoPadma.png')}
        alt='Start logo'
        style={{ height: 80 }}
      />
      <span className='ms-4'>Loading ...</span>
    </div>
  )
}
