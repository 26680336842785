import React from 'react'
import ForgotPasswordForm from '../forgot-password-form/ForgotPasswordForm'
import { useForgotPassword } from '../../hooks/forgot-password-hook'
import InfoCard from 'src/app/components/info-card/InfoCard'

export function ForgotPassword() {

  const { formik, loading, hasErrors, showForm, backToLogin, intl } = useForgotPassword()

  return (
    <>
      <InfoCard
        isShow={hasErrors === false}
        desc={intl.formatMessage({ id: "FORGOT_PASSWORD.CHANGE_PASSWORD" })}
        title={intl.formatMessage({ id: "FORGOT_PASSWORD.EMAIL_SENT" })}
        buttonLabel={intl.formatMessage({ id: "FORGOT_PASSWORD.BACK_TO_LOGIN" })}
        variant={"success"}
        onClick={backToLogin}
      />

      <ForgotPasswordForm
        formik={formik}
        hasErrors={hasErrors}
        isShow={showForm}
        loading={loading}
      />
    </>
  )
}
