import { FormikProps } from 'formik'
import React, { FC, MouseEventHandler } from 'react'
import { IntlShape } from 'react-intl';
import CustomButton from 'src/app/components/CustomButton';
import CustomInputText from 'src/app/components/CustomInputText';
import InfoCardAlert from 'src/app/components/info-card-alert/InfoCardAlert';
import InputPassword from 'src/app/components/InputPassword';
import AuthScreens from '../../AuthScreens';
import { LoginIntitalValues } from '../../hooks/login-hooks'

interface LoginFormProps {
    isShow?: boolean;
    formik: FormikProps<LoginIntitalValues>;
    showVerificationLink: boolean;
    onClickVerificationLink: MouseEventHandler
    intl: IntlShape;
    loading: boolean;
}

const LoginForm: FC<LoginFormProps> = ({
    isShow,
    formik,
    showVerificationLink,
    onClickVerificationLink,
    intl,
    loading
}) => {

    if (!isShow) return null

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='login-form'
        >
            <div className='text-center mb-10'>
                <h1 className='mb-0 text-neutral-500' style={{ fontSize: 24 }}>Login</h1>
            </div>

            <InfoCardAlert
                isShow={formik.status ? true : false}
                variant="warning"
                title={formik.status}
                showVerificationLink={showVerificationLink}
                onClickVerificationLink={onClickVerificationLink}
            />

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <CustomInputText
                    {...formik.getFieldProps('email')}
                    isError={(formik.touched.email && formik.errors.email) ? true : false}
                    errorMessage={formik.errors.email}
                    type='email'
                    name='email'
                    placeholder={intl.formatMessage({ id: "GENERAL.PLACEHOLDER" }, { name: "email" })}
                    autoComplete='off'
                    tabIndex={1}
                    id={'login-email'}
                    maxLength={50}
                    label={intl.formatMessage({ id: "LOGIN.EMAIL" })}
                />
            </div>

            <div className='fv-row mb-8'>
                <InputPassword
                    {...formik.getFieldProps('password')}
                    forgotlink={AuthScreens.FORGOT.PATH}
                    id={'login-password'}
                    tabIndex={2}
                    minLength={8}
                    placeholder={intl.formatMessage({ id: "GENERAL.PLACEHOLDER" }, { name: "password" })}
                    isError={(formik.touched.password && formik.errors.password) ? true : false}
                    errorMessage={formik.errors.password}
                />
            </div>

            <div className='text-md-center'>
                <CustomButton
                    variant='save'
                    className='w-100 w-md-150px btn-lg'
                    disabled={formik.isSubmitting}
                    tabIndex={3}
                    id="login-submit"
                    processing={loading}
                >{intl.formatMessage({ id: "LOGIN.LOGIN" })}</CustomButton>
            </div>
        </form>
    )
}

export default LoginForm