import { useFormik } from "formik"
import { MouseEventHandler, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { getTitle } from "src/app/utils/title-utils"
import * as Yup from 'yup'
import AuthScreens from "../AuthScreens"
import { forgotPassword } from "../redux/AuthCRUD"

export interface ForgotPasswordInitialValues {
    email: string
}

export const useForgotPassword = () => {
    const intl = useIntl()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const initialValues: ForgotPasswordInitialValues = {
        email: '',
    }
    const [showForm, setShowForm] = useState(true)


    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: "ERROR.INCORRECT_EMAIL_FORMAT" }))
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Email" })),
    })

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: true,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(async () => {
                try {
                    const payload = { email: values.email }
                    await forgotPassword(payload)
                    setHasErrors(false)
                    setLoading(false)
                    setIsSuccess(true)

                } catch (e: any) {
                    const response_message = e?.response?.data?.response_schema?.response_message.en;
                    formik.setErrors({ email: response_message })
                    setSubmitting(false)
                    setLoading(false)
                }
            }, 1000)
        },
    })

    const backToLogin: MouseEventHandler = () => {
        history.push(AuthScreens.LOGIN_EMAIL.PATH)
    }

    useEffect(() => {
        const isShowForm = (hasErrors === false && isSuccess) ? false : true
        setShowForm(isShowForm)
    }, [hasErrors, isSuccess])

    useEffect(() => {
        document.title = getTitle(intl.formatMessage({ id: "FORGOT_PASSWORD.FORGOT_PASSWORD" }))
    }, [])


    return {
        formik,
        loading,
        hasErrors,
        showForm,
        backToLogin,
        intl
    }

}