import { useFormik } from "formik"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useCancelableFn } from "src/app/hooks/cancelable-hook"
import { getTitle } from "src/app/utils/title-utils"
import * as Yup from 'yup'
import { loginByEmail, requestAccountVerification } from "../redux/AuthCRUD"
import AuthRedux from "../redux/AuthRedux"

export interface LoginIntitalValues {
    email: string;
    password: string;
}

export const useLogin = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [forceLoading, setForceLoading] = useState<boolean>(false)
    const [expModal, setExpModal] = useState<boolean>(false)
    const [userVerifiedModal, setUserVerifiedModal] = useState<boolean>(false)
    const [doubleLoginModal, setDoubleLoginModal] = useState<boolean>(false)
    const [showVerificationLink, setShowVerificationLink] = useState(false)
    const [responseCode, setResponseCode] = useState('');
    const [responseMessage, setResponseMessage] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()

    const initialValues: LoginIntitalValues = {
        email: '',
        password: '',
    }

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: "ERROR.INCORRECT_EMAIL_FORMAT" }))
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Email" })),
        password: Yup.string()
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Password" }))
    })

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: true,
        validationSchema: loginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setTimeout(async () => {
                try {
                    const result = await loginByEmail(values.email, values.password)
                    const data = result.data.response_output.detail
                    const { access_token, refresh_token, ...user } = data
                    setLoading(false)
                    setSubmitting(false)
                    dispatch(AuthRedux.actions.login(access_token, refresh_token, user))
                    return

                } catch (e: any) {
                    let error_code = e?.response?.data?.response_schema?.response_code
                    let error_message = e?.response?.data?.response_schema?.response_message.en
                    if (error_code === 'PADMA-LOGIN-409') {
                        setStatus('')
                        setDoubleLoginModal(true)
                    } else if (error_code === "PADMA-LOGIN-406") {
                        setStatus(error_message)
                        setShowVerificationLink(true)
                    } else {
                        setStatus(error_message)
                    }
                    setLoading(false)
                    setSubmitting(false)
                }
            }, 1000)
        },
    })

    const forceSubmit = useCancelableFn(
        useCallback((data: any, isActive) => {
            setForceLoading(true)
                ; (async () => {
                    try {
                        if (isActive()) {
                            const result = await loginByEmail(data.email, data.password, 'token', true)
                            const payload = result.data.response_output.detail
                            const { access_token, refresh_token, ...user } = payload
                            dispatch(AuthRedux.actions.login(access_token, refresh_token, user))
                        }
                    } catch (e) {
                        if (isActive()) {
                            formik.setStatus(intl.formatMessage({ id: "ERROR.INCORRECT_EMAIL_AND_PASSWORD" }))
                            setForceLoading(false)
                        }
                    }
                })()
        }, [])
    )

    const resendVerificationLink = useCancelableFn(async (payload: any, isActive) => {

        try {
            const result = await requestAccountVerification(payload)
            const response_code = result.data?.response_schema?.response_code;
            const response_message = result.data?.response_schema?.response_message.en;

            if (isActive()) {
                setResponseCode(response_code)
                setResponseMessage(response_message)

            }
        } catch (e: any) {

            if (isActive()) {
                const response_code = e.response?.data?.response_schema?.response_code;
                const response_message = e.response?.data?.response_schema?.response_message.en;
                setResponseCode(response_code)
                setResponseMessage(response_message)
            }
        }
    })

    const backToLogin = () => {
        setResponseCode('')
        setResponseMessage('')
        formik.setStatus(false)
    }

    useEffect(() => {
        document.title = getTitle('Login')
    }, [])

    return {
        intl,
        formik,
        loading,
        forceLoading,
        expModal,
        setExpModal,
        history,
        userVerifiedModal,
        setUserVerifiedModal,
        doubleLoginModal,
        forceSubmit,
        setDoubleLoginModal,
        showVerificationLink,
        resendVerificationLink,
        responseCode,
        responseMessage,
        backToLogin
    }
}