import React from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import {HeaderToastProvider, HeaderToast} from 'src/app/components/ToastComponent'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const MasterLayout: React.FC = ({children}) => {
  const partners: any = useSelector<RootState>(({auth}) => auth?.user?.partners, shallowEqual)

  return (
    <PageDataProvider>
      <HeaderToastProvider>
        <div
          className='page d-flex flex-row flex-column-fluid'
          style={
            {
              '--theme-icon-color-active': partners?.text_color ?? '#FFE959',
              '--theme-icon-bgcolor-active': partners?.menu_active_color ?? '#000000',
              '--theme-icon-header': partners?.header_color ?? '#FFDD00',
            } as any
          }
        >
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />
            <HeaderToast />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              {/* <Toolbar /> */}
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </HeaderToastProvider>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <ExploreMain /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
