import React, { FC, MouseEventHandler } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import CustomButton from 'src/app/components/CustomButton';
import FormatedParagraph from 'src/app/components/formated-paragraph/FormatedParagraph';

interface InfoCardProps {
  isShow?: boolean;
  title?: string;
  desc?: string;
  variant: "success" | "warning";
  onClick?: MouseEventHandler,
  buttonLabel?: string;
  showActionButton?: boolean
}

const InfoCard: FC<InfoCardProps> = ({
  isShow,
  title,
  desc,
  variant,
  onClick,
  buttonLabel,
  showActionButton = true
}) => {

  if (!isShow) return null

  const icons = {
    "success": "IconCheckGreen.svg",
    "warning": "IconWarning.svg",
  }

  return (
    <div className='d-flex flex-column align-items-stretch'>
      <InlineSVG
        src={`/media/icons/padma/${icons[variant]}`}
        className='align-self-center mb-10 w-50px mt-5'
      />
      <div className='text-center mb-10'>
        <h1 className='text-neutral-500 mb-3'>{title}</h1>
        <div className='text-neutral-400 fs-5 px-5'>
          <FormatedParagraph>{desc}</FormatedParagraph>
        </div>
      </div>
      {showActionButton && (
        <div className='text-center'>
          <CustomButton onClick={onClick} variant='primary' className='w-100'>{buttonLabel}</CustomButton>
        </div>
      )}
    </div>
  )
}

export default InfoCard