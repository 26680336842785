import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const PropertyScreens = {
  LIST_PROPERTY: {
    PATH: '/property/list',
    HEADER_NAME: 'Property List',
    TITLE: 'Property List',
    TITLE_ALT: 'Property List',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_PROPERTY: {
    PATH: '/property/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Property',
    TITLE_ALT: 'Add Property',
    PERMISSION: 'property_list.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_PROPERTY: {
    PATH: '/property/list/detail',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Property',
    TITLE_ALT: 'Detail Property',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_PROPERTY_AVAILABILITY_PLAN: {
    PATH: '/property/list/:property_id/availability-plan/list',
    HEADER_NAME: 'Availability Plan',
    TITLE: 'Availability Plan',
    TITLE_ALT: 'Availability Plan',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_PROPERTY_RATE_PLAN_CALENDAR: {
    PATH: '/property/list/:property_id/rate-plan-calendar/list',
    HEADER_NAME: 'Rate Plan Calendar',
    TITLE: 'Rate Plan Calendar',
    TITLE_ALT: 'Rate Plan Calendar',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  // ROOM
  LIST_ROOM: {
    PATH: '/property/list/:property_id/room/list',
    HEADER_NAME: 'Property Detail',
    TITLE: 'List Room',
    TITLE_ALT: 'List Room',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  ADD_ROOM: {
    PATH: '/property/list/:property_id/room/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Room',
    TITLE_ALT: 'Add Room',
    PERMISSION: 'property_list.create',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_ROOM_INFORMATION: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/room-information',
    HEADER_NAME: 'Room Detail',
    TITLE: 'Room Information',
    TITLE_ALT: 'Room Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_ROOM_OCCUPANCY: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/occupancy',
    HEADER_NAME: 'Detail',
    TITLE: 'Occupancy',
    TITLE_ALT: 'Occupancy',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  ADD_ROOM_GALLERY: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/room-gallery/list/add',
    HEADER_NAME: 'Room Detail',
    TITLE: 'Room Gallery',
    TITLE_ALT: 'Room Gallery',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_ROOM_GALLERY: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/room-gallery/list',
    HEADER_NAME: 'Detail',
    TITLE: 'Room Gallery',
    TITLE_ALT: 'Room Gallery',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROOM_AMENITIES: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/amenities/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Amenities',
    TITLE_ALT: 'Amenities',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROOM_AMENITIES: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/amenities/list',
    HEADER_NAME: 'Detail',
    TITLE: 'Amenities',
    TITLE_ALT: 'Amenities',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_ROOM_RATE: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate',
    HEADER_NAME: 'Detail',
    TITLE: 'Rate',
    TITLE_ALT: 'Rate',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_ROOM_RATE_PLAN_CALENDAR: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate-plan-calendar/list/detail/:rate_plan_id',
    HEADER_NAME: 'Rate Plan Calendar',
    TITLE: 'Rate Plan Calendar',
    TITLE_ALT: 'Rate Plan Calendar',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  LIST_RATE_PLAN: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate/list',
    HEADER_NAME: 'Rate Plan',
    TITLE: 'Rate List',
    TITLE_ALT: 'Rate',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // DYNAMIC PRICING
  LIST_DYNAMIC_PRICING: {
    PATH: '/property/list/:property_id/dynamic-pricing/list',
    HEADER_NAME: 'Dynamic Pricing',
    TITLE: 'Dynamic Pricing List',
    TITLE_ALT: 'Dynamic Pricing',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_DYNAMIC_PRICING: {
    PATH: '/property/list/:property_id/dynamic-pricing/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Dynamic Pricing',
    TITLE_ALT: 'Add Dynamic Pricing',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_DYNAMIC_PRICING: {
    PATH: '/property/list/:property_id/dynamic-pricing/detail/:dynamic_pricing_id',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Dynamic Pricing',
    TITLE_ALT: 'Detail Dynamic Pricing',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  EDIT_DYNAMIC_PRICING: {
    PATH: '/property/list/:property_id/dynamic-pricing/edit/:dynamic_pricing_id',
    HEADER_NAME: 'Edit',
    TITLE: 'Edit Dynamic Pricing',
    TITLE_ALT: 'Edit Dynamic Pricing',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_RATE_PLAN: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate/list/detail/:rate_id',
    HEADER_NAME: 'Rate Plan Information',
    TITLE: 'Rate Plan',
    TITLE_ALT: 'Rate',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_RATE_POLICY: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate/list/detail/:rate_id/policy',
    HEADER_NAME: 'Rate Plan Policy',
    TITLE: 'Policy',
    TITLE_ALT: 'Policy',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  ADD_RATE_PLAN: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Rate Plan',
    TITLE_ALT: 'Add Rate Plan',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  ADD_RATE_PLAN_POLICY: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/rate/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Rate Plan',
    TITLE_ALT: 'Add Rate Plan',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  EDIT_RATE_INFORMATION: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/rate/list/edit/:rate_id',
    HEADER_NAME: 'Edit',
    TITLE: 'Rate Information',
    TITLE_ALT: 'Rate Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_RATE_POLICY: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/rate/list/edit/:rate_id/policy',
    HEADER_NAME: 'Edit',
    TITLE: 'Rate Policy',
    TITLE_ALT: 'Rate Policy',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },


  EDIT_ROOM_INFORMATION: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/room-information',
    HEADER_NAME: 'Edit',
    TITLE: 'Room Information',
    TITLE_ALT: 'Room Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  EDIT_ROOM_OCCUPANCY: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/occupancy',
    HEADER_NAME: 'Edit',
    TITLE: 'Occupancy',
    TITLE_ALT: 'Occupancy',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  EDIT_ROOM_GALLERY: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/room-gallery/list',
    HEADER_NAME: 'Edit',
    TITLE: 'Room Gallery',
    TITLE_ALT: 'Room Gallery',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  EDIT_ROOM_AMENITIES: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/amenities',
    HEADER_NAME: 'Edit',
    TITLE: 'Amenities',
    TITLE_ALT: 'Amenities',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  EDIT_ROOM_RATE: {
    PATH: '/property/list/:property_id/room/list/edit/:room_id/rate',
    HEADER_NAME: 'Edit',
    TITLE: 'Rate',
    TITLE_ALT: 'Rate',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  DETAIL_ROOM_AVAILABILITY_PLAN: {
    PATH: '/property/list/:property_id/room/list/detail/:room_id/availability-plan',
    HEADER_NAME: 'Detail',
    TITLE: 'Availability Plan',
    TITLE_ALT: 'Availability Plan',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  // PROPERTY_INFORMATION
  DETAIL_PROPERTY_INFORMATION: {
    PATH: '/property/list/:property_id/property-information/list/detail',
    HEADER_NAME: 'Property Detail',
    TITLE: 'Property Information',
    TITLE_ALT: 'Property Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_PROPERTY_INFORMATION: {
    PATH: '/property/list/:property_id/property-information/list/edit',
    HEADER_NAME: 'Property Detail',
    TITLE: 'Property Information',
    TITLE_ALT: 'Property Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  // SOCIAL_MEDIA
  DETAIL_SOCIAL_MEDIA: {
    PATH: '/property/list/:property_id/social-media/list/detail',
    HEADER_NAME: 'Property Detail',
    TITLE: 'Property Information',
    TITLE_ALT: 'Property Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_SOCIAL_MEDIA: {
    PATH: '/property/list/:property_id/social-media/list/edit',
    HEADER_NAME: 'Property Detail',
    TITLE: 'Social Media',
    TITLE_ALT: 'Social Media',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  // PROPERTY GALLERY
  LIST_PROPERTY_GALLERY: {
    PATH: '/property/list/:property_id/property-gallery/list',
    HEADER_NAME: 'Property Detail',
    TITLE: 'List Property Gallery',
    TITLE_ALT: 'List Property Gallery',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  ADD_PROPERTY_GALLERY: {
    PATH: '/property/list/:property_id/property-gallery/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Property Gallery',
    TITLE_ALT: 'Add Property Gallery',
    PERMISSION: 'property_list.create',
    breadcrumbs: defaultBreadcrumbs,
  },

  // FACILITIES
  LIST_FACILITIES: {
    PATH: '/property/list/:property_id/facilities/list',
    HEADER_NAME: 'Property Detail',
    TITLE: 'List Facilities',
    TITLE_ALT: 'List Facilities',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  // CONCIERGE
  LIST_CONCIERGE: {
    PATH: '/property/list/:property_id/concierge/list',
    HEADER_NAME: 'Hotel Information',
    TITLE: 'List Hotel Information',
    TITLE_ALT: 'List Hotel Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_CONCIERGE: {
    PATH: '/property/list/:property_id/concierge/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Hotel Information',
    TITLE_ALT: 'Add Hotel Information',
    PERMISSION: 'property_list.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CONCIERGE: {
    PATH: '/property/list/:property_id/concierge/list/detail/:concierge_id/information',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Hotel Information',
    TITLE_ALT: 'Detail Hotel Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CONCIERGE_INFORMATION: {
    PATH: '/property/list/:property_id/concierge/list/detail/:concierge_id/information',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Hotel Information',
    TITLE_ALT: 'Detail Hotel Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CONCIERGE_GALLERY: {
    PATH: '/property/list/:property_id/concierge/list/detail/:concierge_id/gallery',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Hotel Information',
    TITLE_ALT: 'Detail Hotel Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CONCIERGE_ATTACHMENT: {
    PATH: '/property/list/:property_id/concierge/list/detail/:concierge_id/attachment',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Hotel Information',
    TITLE_ALT: 'Detail Hotel Information',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CONCIERGE: {
    PATH: '/property/list/:property_id/concierge/list/edit/:concierge_id/information',
    HEADER_NAME: 'Edit',
    TITLE: 'Edit Hotel Information',
    TITLE_ALT: 'Edit Hotel Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CONCIERGE_INFORMATION: {
    PATH: '/property/list/:property_id/concierge/list/edit/:concierge_id/information',
    HEADER_NAME: 'Edit',
    TITLE: 'Edit Hotel Information',
    TITLE_ALT: 'Edit Hotel Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CONCIERGE_GALLERY: {
    PATH: '/property/list/:property_id/concierge/list/edit/:concierge_id/gallery',
    HEADER_NAME: 'Edit',
    TITLE: 'Edit Hotel Information',
    TITLE_ALT: 'Edit Hotel Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CONCIERGE_ATTACHMENT: {
    PATH: '/property/list/:property_id/concierge/list/edit/:concierge_id/attachment',
    HEADER_NAME: 'Edit',
    TITLE: 'Edit Hotel Information',
    TITLE_ALT: 'Edit Hotel Information',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  // EXTRAS
  LIST_EXTRA: {
    PATH: '/property/list/:property_id/extras/list',
    HEADER_NAME: 'Extras',
    TITLE: 'List Extras',
    TITLE_ALT: 'List Extras',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_EXTRA: {
    PATH: '/property/list/:property_id/extras/list/add',
    HEADER_NAME: 'Add',
    TITLE: 'Add Extras',
    TITLE_ALT: 'Add Extras',
    PERMISSION: 'property_list.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_EXTRA: {
    PATH: '/property/list/:property_id/extras/list/detail/:extra_id',
    HEADER_NAME: 'Detail',
    TITLE: 'Detail Extras',
    TITLE_ALT: 'Detail Extras',
    PERMISSION: 'property_list.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_EXTRA: {
    PATH: '/property/list/:property_id/extras/list/edit/:extra_id',
    HEADER_NAME: 'Edit',
    TITLE: 'Edit Extras',
    TITLE_ALT: 'Edit Extras',
    PERMISSION: 'property_list.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const propertyBreadcrumbs = generateBreadcrumb([PropertyScreens.LIST_PROPERTY])
PropertyScreens.LIST_PROPERTY.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.ADD_PROPERTY.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_PROPERTY.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.LIST_ROOM.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_PROPERTY_INFORMATION.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.EDIT_PROPERTY_INFORMATION.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_SOCIAL_MEDIA.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.EDIT_SOCIAL_MEDIA.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.LIST_PROPERTY_GALLERY.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.ADD_PROPERTY_GALLERY.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.LIST_FACILITIES.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.LIST_CONCIERGE.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.ADD_CONCIERGE.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_CONCIERGE.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.EDIT_CONCIERGE.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.ADD_ROOM_AMENITIES.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_ROOM_AMENITIES.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_ROOM_AVAILABILITY_PLAN.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_PROPERTY_AVAILABILITY_PLAN.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.LIST_EXTRA.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.ADD_EXTRA.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.DETAIL_EXTRA.breadcrumbs = () => propertyBreadcrumbs
PropertyScreens.EDIT_EXTRA.breadcrumbs = () => propertyBreadcrumbs

export default PropertyScreens
