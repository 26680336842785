import { useFormik } from 'formik'
import { MouseEventHandler, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useCancelableFn } from 'src/app/hooks/cancelable-hook'
import { getTitle } from 'src/app/utils/title-utils'
import { RootState } from 'src/setup'
import * as Yup from 'yup'
import AuthScreens from '../AuthScreens'
import { logoutUser, requestAccountVerification, saveResetPassword, verifyResetPassword } from '../redux/AuthCRUD'
import AuthRedux from '../redux/AuthRedux'

type ResponseCode = "PADMA-CHANGE-PASSWORD-200" | "PADMA-CHANGE-PASSWORD-202" | "PADMA-CHANGE-PASSWORD-400" | "PADMA-CHANGE-PASSWORD-410"

export const useCreateNewPassword = () => {

    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [responseCode, setResponseCode] = useState<ResponseCode | undefined>();
    const [responseMessage, setResponseMessage] = useState<string>('');
    const history = useHistory()
    let query = new URLSearchParams(useLocation().search)
    const token = query.get('token') as string
    const loggedInEmail = useSelector(({ auth }: RootState) => auth.user?.email, shallowEqual)
    const dispatch = useDispatch()

    const resetSchema = Yup.object().shape({
        new_password: Yup.string()
            .matches(/^(?=.*[A-Z])(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*[0-9]).{8,}$/, () => (
                <>
                    <div>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.MUST_HAVE" })}</div>
                    <ul>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.MIN" }, { count: 8 })}</li>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.LETTER_AND_NUMBER" })}</li>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.LOWERCASE_AND_UPPERCASE" })}</li>
                        <li>{intl.formatMessage({ id: "CREATE_NEW_PASSWORD.SPECIAL_CHARACTERS" })}</li>
                    </ul>
                </>
            ))
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Password" })),
        confirm_password: Yup.string()
            .min(8, intl.formatMessage({ id: "ERROR.MIN" }, { count: 8 }))
            .required(intl.formatMessage({ id: "ERROR.REQUIRED" }, { name: "Confirm password" }))
            .test('equal', intl.formatMessage({ id: "CREATE_NEW_PASSWORD.PASSWORD_DOES_NOT_MATCH" }), function (v) {
                const ref = Yup.ref('new_password')
                return v === this.resolve(ref)
            }),
    })

    const initialValues = {
        new_password: '',
        confirm_password: '',
    }

    const submitFn = useCancelableFn(async (payload: any, isActive) => {
        try {
            const result = await saveResetPassword(token ?? '', payload)
            const response_code = result.data?.response_schema?.response_code;
            const response_message = result.data?.response_schema?.response_message?.en;

            if (loggedInEmail) {
                try {
                    await logoutUser()
                } catch (e) {
                    //do nothing
                }
                dispatch(AuthRedux.actions.logout())
            }
            if (isActive()) {
                setResponseCode(response_code)
                setResponseMessage(response_message)

                setLoading(false)
                formik.setSubmitting(false)
            }

        } catch (e: any) {
            if (isActive()) {
                let response_code = e?.response?.data?.response_schema?.response_code
                let response_message = e?.response?.data?.response_schema?.response_message.en
                setResponseCode(response_code)
                setResponseMessage(response_message)
                setLoading(false)
                formik.setSubmitting(false)
            }
        }
    })

    const backToLogin: MouseEventHandler = () => {
        history.push(AuthScreens.LOGIN_EMAIL.PATH)
    }

    const resendVerificationLink = useCancelableFn(async (payload: any, isActive) => {

        try {
            const result = await requestAccountVerification(payload)
            const response_code = result.data?.response_schema?.response_code;

            if (isActive()) {
                setResponseCode(response_code)
                setLoading(false)
                formik.setSubmitting(false)
            }
        } catch (e: any) {

            if (isActive()) {
                const response_code = e.response?.data?.response_schema?.response_code;
                setResponseCode(response_code)
            }
        } finally {
            setLoading(false)
            formik.setSubmitting(false)
        }
    })


    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: true,
        validationSchema: resetSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            const payload = {
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            }
            submitFn(payload)
        },
    })


    useEffect(() => {
        verifyResetPassword(token)
            .catch(e => {
                const response_code = e?.response?.data?.response_schema?.response_code
                const response_message = e?.response?.data?.response_schema?.response_message?.en
                setResponseCode(response_code)
                setResponseMessage(response_message)
            })


    }, [token])

    useEffect(() => {
        if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
        document.title = getTitle(intl.formatMessage({ id: "CREATE_NEW_PASSWORD.CREATE_NEW_PASSWORD" }))
    }, [history, token])

    return {
        formik,
        loading,
        backToLogin,
        responseCode,
        responseMessage,
        resendVerificationLink,
        token,
        intl
    }
}