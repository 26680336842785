/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import DoubleLoginModal from '../double-login-modal/DoubleLoginModal'
import { useLogin } from '../../hooks/login-hooks'
import InfoCard from 'src/app/components/info-card/InfoCard'
import LoginForm from '../login-form/LoginForm'


export function Login() {
  const {
    intl,
    formik,
    loading,
    forceLoading,
    doubleLoginModal,
    forceSubmit,
    setDoubleLoginModal,
    showVerificationLink,
    resendVerificationLink,
    responseCode,
    responseMessage,
    backToLogin
  } = useLogin()

  return (
    <>

      <InfoCard
        variant='success'
        isShow={responseCode === "PADMA-CHANGE-PASSWORD-NOTIFICATION-202"}
        title={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.EMAIL_SENT" })}
        buttonLabel={intl.formatMessage({ id: "CREATE_NEW_PASSWORD.BACK_TO_LOGIN" })}
        onClick={backToLogin}
        desc={responseMessage}
      />

      <LoginForm
        isShow={responseCode === ""}
        formik={formik}
        showVerificationLink={showVerificationLink}
        intl={intl}
        loading={loading}
        onClickVerificationLink={() => resendVerificationLink({ email: formik.values.email })}
      />

      <DoubleLoginModal
        show={doubleLoginModal}
        handleSuccess={() => forceSubmit({ email: formik.values.email, password: formik.values.password })}
        handleClose={() => setDoubleLoginModal(false)}
        processing={forceLoading}
      />
    </>
  )
}
